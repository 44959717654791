import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { viewAdminPage } from "../../components/Layouts/AdminLayout";
import { AdminContext } from "../../context/AdminProvider";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { apiService } from "../../services/ApiService";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const { currentUser, setCurrentUser, myRole, setMyRole } = useContext(AdminContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const savedUsername = Cookies.get("username");
    const savedPassword = Cookies.get("password");
    const savedRememberMe =
      Cookies.get("rememberMe") == undefined || Cookies.get("rememberMe") == null ? false : Cookies.get("rememberMe");
    if (savedRememberMe && Boolean(savedRememberMe) && savedRememberMe != "false") {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let body = {
      emailId: data.get("email"),
      password: data.get("password"),
    };

    if (!body.emailId || !body.password) {
      toast.warn("Please fill the required fields!");
      return;
    }
    toast.loading("Processing...");
    setLoading(true);

    apiService
      .post("authenticate/admin/login", body)
      .then((res) => res?.data || res)
      .then((response) => {
        toast.dismiss();
        if (response.resultCode > 0) {
          sessionStorage.setItem("isLogin", true);
          console.log("RES:", response.data, response.data?.organizations);
          setMyRole(response?.data?.role || "USER");

          toast.success(response.message || "Done");

          if (rememberMe) {
            Cookies.set("username", body.emailId);
            Cookies.set("password", body.password);
            Cookies.set("rememberMe", "true");
          } else {
            Cookies.set("username", "");
            Cookies.set("password", "");
            Cookies.set("rememberMe", "false");
          }

          navigate("organizations");
        } else {
          toast.error(response.message || "Fail");
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.dismiss();
        setLoading(false);
        console.log(err);
        if (err?.response && err?.response?.data) {
          toast.error(err?.response?.data?.message);
        }
      });

    sessionStorage.setItem("jwtToken", "success");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Paper elevation={3} sx={{ p: 5 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src="/Valydate4u.png" style={{ margin: "auto 0.5rem", width: "16rem", height: "100%" }} />
          </Box>
          <Typography component="h1" variant="h5" align="center">
            Admin sign in
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }} autoFocus>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              autoComplete="current-password"
              InputLabelProps={{ shrink: true }}
            />

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <FormControlLabel
                value={rememberMe}
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => {
                      setRememberMe(e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label="Remember me"
              />
            </Box>

            <Button disabled={loading} type="submit" fullWidth variant="contained" sx={{ mt: 1 }}>
              Sign In
            </Button>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

export default LoginPage;
