import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.withCredentials = true;
axios.defaults.credentials = "include";

let Hosted_Base_URL = window?.location?.origin;

const API_BASE_URL = process.env?.REACT_APP_API_BASE_URL || `${Hosted_Base_URL}/api`;


const apiService = axios.create({ baseURL: `${API_BASE_URL}/` });

// Add a request interceptor
apiService.interceptors.request.use(
  function (config) {
    // Append opkey as a query parameter to each request
    const opkey = sessionStorage.getItem("opkey");
    if (opkey) {
      config.params = { ...config.params, opkey };
    }
    return config;
  },
  function (error) {
    
    return Promise.reject(error);
  }
);

apiService.interceptors.response.use(
  response => response,  // Handle successful responses
  error => {
    if(error?.status === 401){
      toast.error("Unauthorized request! Session expired!");
      setTimeout(() => {
        sessionStorage.removeItem("isLogin");
        sessionStorage.removeItem("jwtToken");
        window.location.href = "/";
      }, 1800);
      return Promise.reject(`Unauthorized request! Session expired!`);
    }
    
    // For all other errors, just reject
    return Promise.reject(error);
  }
);
export { apiService };
