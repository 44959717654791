import { Box, Button, Container, CssBaseline, Divider, Grid, Paper, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/ApiService";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
  alignItems: "center",
});

const PaymentBillUpload = () => {
  const { reqId, type } = useParams();
  const [reqDetails, setReqDetails] = useState();

  useEffect(() => {
    const currentUrl = window.location.href;

    const url = new URL(currentUrl);
    const opkey = url.searchParams.get("opkey");

    sessionStorage.setItem("opkey", opkey);

    getRequestDetails(reqId, type, opkey);
  }, []);

  let fetching = false;
  const getRequestDetails = async (reqId, type, opkey) => {
    if (fetching) return;
    fetching = true;

    apiService
      .post(`exposed/license/request/details`, { reqId, type, opkey })
      .then((res) => res?.data || res)
      .then((response) => {
        toast.dismiss();
        fetching = false;
        if (response.resultCode > 0) {
          toast.success(response.message);
          setReqDetails(response?.data);
        } else {
          toast.error(response.message || "Fail");
        }
      })
      .catch((err) => {
        toast.dismiss();
        fetching = false;
        console.log(err);
        if (err?.response && err?.response?.data) {
          toast.error(err?.response?.data?.message);
        }
      });
  };

  let isSubmiting = false;

  const handleFileUpload = (e) => {
    try {
      // console.log("handleFileUpload ", { T: e?.target.files });

      const file = e.target.files[0];
      if (file) {
        if (file?.type !== "application/pdf" && !file?.type?.startsWith("image/")) {
          // It's NOT a PDF or image
          toast.warning("File should be PDF or Image (jpg/png)");
          e.target.files = null;
          return;
        } else {
          // It's neither a PDF nor an image

          if (isSubmiting) {
            return;
          }
          isSubmiting = true;

          const formData = new FormData();
          formData.append("file", file);

          if (!file) {
            toast.warn("Please select the file (image/pdf)");
            return;
          }

          // apiService.post(`license/api/uploadBillProof/${reqId}/${type}`, formData,{
          apiService
            .post(`exposed/license/uploadBillProof/${reqId}/${type}`, formData, {
              "Content-Type": "multipart/form-data",
            })
            .then((res) => res?.data || res)
            .then((response) => {
              toast.dismiss();
              isSubmiting = false;
              if (response.resultCode > 0) {
                toast.success(response.message);

                setTimeout(() => {
                  window.location.href = "/billUpload/success";
                }, 300);
              } else {
                toast.error(response.message || "Fail");
              }
            })
            .catch((err) => {
              toast.dismiss();
              isSubmiting = false;
              console.log(err);
              if (err?.response && err?.response?.data) {
                toast.error(err?.response?.data?.message);
              }
            });
        }
      }
    } catch (error) {
      console.log(error);
      isSubmiting = false;
      toast.error("Exception: " + error?.message);
    }
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <form>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img src="/Valydate4u.png" style={{ margin: "auto 0.5rem", width: "16rem", height: "100%" }} />
            </Box>

            <Typography component="h5" variant="h5" align="center">
              Upload Bill Proof
            </Typography>

            <Divider sx={{ my: 2 }} />

            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12}>
                <Grid container alignItems="start">
                  <Grid item xs={12} sm={4}>
                    <Typography variant="p" color="gray">
                      Product
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Typography variant="p">
                      :{" "}
                      {reqDetails?.product?.productName
                        ? `${reqDetails?.product?.productName || ""} (${reqDetails?.product?.productCode || "--"})`
                        : "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="p" color="gray">
                      Package
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Typography variant="p">
                      : {reqDetails?.package?.name ? `${reqDetails?.package?.name || ""}` : "--"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant="p" color="gray">
                      Duration
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Typography variant="p">
                      : {reqDetails?.package ? `${reqDetails?.package?.durationDays || ""} Days` : "--"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant="p" color="gray">
                      Price
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Typography variant="p">
                      : {reqDetails?.package ? `$${reqDetails?.package?.price || ""}` : "--"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} sx={{ my: "1rem" }} justifyContent="center" alignItems="center">
                <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
                  <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                    Upload file & Submit
                    <VisuallyHiddenInput type="file" accept="image/png, image/jpeg,.pdf" onChange={handleFileUpload} />
                  </Button>
                </Box>
                <Typography variant="caption" color="red">
                  Note: Please upload the screenshot of the payment successful transaction or upload the PDF receipt for
                  verification.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Container>
    </>
  );
};

export default PaymentBillUpload;
