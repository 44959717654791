import React from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Grid container justifyContent="center" sx={{ minHeight: "100vh", backgroundColor: "whitesmoke" }}>
      <Grid item xs={12} sm={12} md={9} lg={8} sx={{color:'rgba(50, 71, 92, 0.87)'}}>
        <Box p={3} bgcolor="white" sx={{ py: 2, px: 3 }}>
          <Box textAlign="center" sx={{ backgroundColor: "#1976d2", color: "white", mt: 1, p: 2 }}>
            <Typography variant="h4">Global Privacy Policy</Typography>
          </Box>

          <Divider sx={{ my: 1 }} />

          <Typography variant="body1" sx={{ mb: 2 }}>
            This Privacy Policy explains how we process the data of people who use our verification or authentication
            services. There are separate sections at the end of the policy covering how we process data of job
            applicants and business contacts.
          </Typography>

          <Divider sx={{ my: 1 }} />

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            1. Introduction
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <ul>
              <li>
                Privacy is a fundamental human right. Your personal information is exactly that, yours. At
                Valydate4u.com Product by BlueBricks Pty Ltd - Australia , we want to keep it that way. That is why
                privacy is paramount to us, in everything we do, and we are committed to respecting your privacy.
              </li>

              <li>
                Our Privacy Policy sets out how we collect, hold, use, store and disclose your personal and sensitive
                information. We may change our Privacy Policy from time to time by publishing changes to it on our
                website. We encourage you to check our website periodically to ensure that you are aware of our current
                Privacy Policy.
              </li>
              <li>
                For the purposes of this Privacy Policy, ‘us’ ‘we’ or ‘our’ means the BlueBricks’s different businesses
                (listed at the end of this Policy). We are bound by different global data protection legislation
                depending on where you live, including the EU and UK GDPR, various US state laws (including the
                Californian Consumer Privacy Act) and the Australian Privacy Principles in the Privacy Act 1988.
              </li>
              <li>
                Personal information includes information about an individual that is reasonably identifiable. For
                example, this may include your name, age, gender, postcode and contact details.
              </li>
              <li>Sensitive information includes biometric information we process when we perform face matching.</li>
            </ul>
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            2. Special Biometric Data Notice for Illinois, Washington and Texas Residents
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <ul>
              <li>
                For residents of Illinois, Washington or Texas, if our clients require you to provide us with any
                document that contains your photograph or if you need to verify or authenticate your identity by
                providing a photograph or video of yourself, the data derived from your face that we collect and process
                on behalf of our clients to provide the verification or authentication service may be considered
                biometric data. We will only use your data for the purpose of verifying or authenticating your identity
                and the prevention of fraud, and for no other purpose. Your biometric data will be stored as long as
                required for these purposes, but no longer than three years.
              </li>
            </ul>
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            3. What personal and sensitive information do we collect and hold?
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            We may collect and hold the following types of personal information and sensitive information:
            <ul>
              <li>name;</li>
              <li>mailing or street address;</li>
              <li>mobile telephone number;</li>
              <li>email address;</li>
              <li>age or date of birth;</li>
              <li>nationality;</li>
              <li>
                government related identifiers, such as your licence number and class, Medicare number, state or
                national ID card number, passport number, and birth or marriage certificate number;
              </li>
              <li>indicators of fraudulent activity;</li>
              <li>
                other information identifiable from scanned ID documents you provide, such as your organ donor status or
                photographs of your face;
              </li>
              <li>
                biometric information, such as our ‘Sonic ID’ (a one way hash) we create from video footage or
                photographs of your face;
              </li>
              <li>information obtained from fraud-prevention services and document verification services;</li>
              <li>
                your device ID, device type, geo-location information, computer and connection information, IP address
                and standard web log information; and
              </li>
              <li>
                any other personal information that may be required in order to provide our services to our clients.
              </li>
            </ul>
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            4. How do we collect your personal and sensitive information?
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <ul>
              <li>
                We may collect these types of personal or sensitive information either directly from you, or from third
                parties when you use our verification or authentication services.
              </li>
              <li>
                We automatically receive and record certain information from your mobile device. This may include such
                information as the third-party website or application into which the services are integrated, the date
                and time that you use the services, your IP address and domain name, your software and hardware
                attributes (including operating system, device model, and hashed device fingerprint information), and
                your general geographic location (e.g., your city, state, or metropolitan region).
              </li>
              <li>
                Where you provide us with personal or sensitive information on behalf of someone else, you must ensure
                you are permitted to provide us with their personal or sensitive information. You also need to tell them
                how to find a copy of this Privacy Policy.
              </li>
              <li>
                We may receive personal, sensitive or anonymised information about you from our clients where they make
                use of our services. This information may include a client ID that identifies you in a database, as well
                as the categories of information set out above.
              </li>
              <li>
                Data Access and Consent: As part of our software product's functionality, we may integrate with
                third-party services such as Google Drive, Dropbox, Microsoft OneDrive, Xero Accounting, and others.
                This integration allows us to enhance your user experience by providing access to your profile data and
                documents stored on these platforms.
              </li>
              <li>
                User Consent: Access to your data from these third-party sources will only occur with your explicit
                consent. During the integration setup process, you will be prompted to grant permission for our software
                to access your data from the specified sources. Your consent is paramount, and we will not access any of
                your data without it.
              </li>
              <li>
                Data Control: You maintain full control over your data at all times. You have the right to revoke our
                access to your data from these third-party sources at any time. This can typically be done through the
                settings or permissions section of your account on the respective platforms. Additionally, should you
                choose to close your account with us, access to your data from these sources will be automatically
                terminated.
              </li>
              <li>
                Security Measures: We take the security and privacy of your data very seriously. Our software employs
                industry-standard security measures to safeguard the integrity and confidentiality of the data accessed
                from third-party sources. These measures include encryption, access controls, regular security audits,
                and compliance with relevant data protection regulations.
              </li>
              <li>
                Transparency and Communication: We are committed to maintaining transparency regarding the use of your
                data. Our privacy policy clearly outlines how your data is accessed, used, and protected within our
                software. If you have any questions or concerns regarding data access or privacy, please don't hesitate
                to contact us.
              </li>
              <li>
                Google Specific User Data Policy: The utilization and transfer of information obtained from Google APIs
                by our software complies with the{" "}
                <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank">
                  Google API Services User Data Policy
                </a>
                , which encompasses the Limited Use stipulations.
              </li>
              <li>
                By using our software and agreeing to our privacy policy, you acknowledge and consent to the access of
                your data from third-party sources as described herein. Your trust is of utmost importance to us, and we
                are dedicated to upholding the highest standards of data privacy and security.
              </li>
            </ul>
            <small>Retention of your personal and sensitive information</small>
            <br />
            <br />
            We will retain your personal and sensitive information only for as long as is necessary for the purposes set
            out in this Privacy Policy. We will retain and use your personal and sensitive information to the extent
            necessary to comply with our legal obligations (for example, if we are required to retain your data to
            comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            5. Why do we collect, hold, use and disclose personal and sensitive information?
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            We may collect, hold, use and disclose your personal and sensitive information for the following purposes:
            <ul>
              <li>
                to provide verification or authentication services, where you are seeking to access one of our clients’
                products or services (or the products or services of third parties, where our clients act as brokers,
                resellers, referrers or representatives of such parties);
              </li>
              <li>to prevent fraudulent behaviour being undertaken on our products for any of our clients;</li>
              <li>
                to operate, protect, improve and optimise our website or apps, business and our clients’ and users’
                experience, such as to perform analytics, conduct research and create new products. We use synthetic
                data or information about the characteristics of documents (with no personal data) to train our
                algorithms; and
              </li>
              <li>
                to comply with our legal obligations, and perhaps to resolve any disputes that we may have with any of
                our clients or users.
              </li>
            </ul>
            We may also be entitled to use personal information for any purpose which is related to the above purposes.
            <br />
            <br />
            We do <b>not</b> use your personal data or biometric data to train our algorithms or products.
            <br />
            <br />
            We may use de-identified, aggregated information to share insights about users of our services, such as by
            publishing a report on trends in the usage of such services.
            <br />
            <br />
            If we think you are impersonating someone, using a synthetic identity or using a stolen identity then we may
            retain unique identifiers in a fraud database to allow us to identify if you try to commit fraud against us
            or our clients again. We check each entry into our fraud database manually to ensure no victims of fraud are
            put into it. Please contact <b>connect@blue-bricks.com</b> if you think your identifier is in our fraud
            database and should not be.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            6. Other circumstances where we may disclosure of your personal or sensitive information
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <small>Business Transactions</small>
            <br />
            <br />
            If we are involved in a merger, acquisition or asset sale, your personal and sensitive information may be
            transferred. We will endeavour to provide notice before your personal and sensitive information is
            transferred and becomes subject to a different Privacy Policy.
            <br />
            <br />
            <small>Law enforcement</small>
            <br />
            <br />
            Under certain circumstances, we may be required to disclose your personal and sensitive information if
            required to do so by law or in response to valid requests by public authorities (e.g. a court or a
            government agency).
            <br />
            <br />
            <small>Other legal requirements</small>
            <br />
            <br />
            We may disclose your personal and sensitive information in the good faith belief that such action is
            necessary to:
            <ul>
              <li>comply with a legal obligation;</li>
              <li>protect and defend the rights or property of the Company;</li>
              <li>prevent or investigate possible wrongdoing in connection with our services;</li>
              <li>protect the personal safety of users of the services or the public; and</li>
              <li>protect against legal liability.</li>
            </ul>
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            7. Do we use your personal information for direct marketing?
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            We do not use personal information provided to us or collected as part of our identity verification or
            authentication services for marketing purposes.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            8. To whom do we disclose your personal information?
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            We may disclose personal information (but not sensitive or biometric data) for the purposes described in
            this Privacy Policy to:
            <ul>
              <li>companies within the BlueBricks group where necessary to provide our services to our clients;</li>
              <li>
                our clients and third parties (where our clients act as resellers or representatives of such parties),
                where you are seeking to access their products and/or services and are required to verify your identity
                in order to do so. We do not sell any of your data to any third party;
              </li>
              <li>
                our employees and contractors, for the purposes of managing our products and systems and providing our
                services;
              </li>
              <li>
                third party suppliers and service providers (including providers of document verification services to
                help us verify the validity of identity documents you disclose to us, and other providers for the
                operation of our websites and/or our business or in connection with providing our products and services
                to you);
              </li>
              <li>specific third parties authorised by you to receive information held by us;</li>
              <li>
                other persons, including government agencies, regulatory bodies and law enforcement agencies, or as
                required, authorised or permitted by law; and
              </li>
              <li>as otherwise required or permitted by law.</li>
            </ul>
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            9. Overseas transfer of personal and sensitive information
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            We use localised instances of cloud hosting so that overseas transfers are limited.
            <ul>
              <li>European, UK and Middle Eastern clients – all data is processed within the EU or the UK</li>
              <li>Americas – all data is processed within the USA</li>
              <li>Asia-Pacific – all data is processed within Australia or Singapore or India</li>
            </ul>
            We may make limited transfers of personal data within our group companies to Australia or the USA from the
            UK or the EU in order to provide support and customer success services to our clients. We make this transfer
            under the EU approved Standard Contractual Clauses (with the UK addendum).
            <br />
            <br />
            To send you an SMS message to start the verification journey your mobile number only is processed in the USA
            (other than Australian residents for whom we use an Australian supplier). Our SMS providers (Twilio Inc and
            8x8 Inc) exports the data under its European Commission approved Binding Corporate Rules. To check that your
            address is in the right format we send your address only to a supplier in either the UK, the USA or
            Australia. Our suppliers all export the data under the EU approved Standard Contractual Clauses (with the UK
            addendum). Those suppliers are ISO 27001 and SOC2 certified.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            10. Security and storage
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            We take data security very seriously and are externally audited against the ISO 27001 and SOC2 Type 2
            standards each year. We take reasonable physical, electronic, and procedural measures to protect your
            personal and sensitive information against loss or unauthorised access, use, interference, modification, or
            deletion. User data is hosted by AWS in cloud environments which we manage and control.
            <br />
            <br />
            Among other things, we encrypt personal and sensitive information both in transit and at rest and we
            implement robust disaster recovery and business continuity procedures.
            <br />
            <br />
            Personal and sensitive information will be held in a secure environment. We have security measures in place
            which are intended to protect personal and sensitive information. The key methods of securing the storage of
            personal and sensitive information include:
            <ul>
              <li>
                Secure access to electronic and physical records containing personal and sensitive information, via
                password protected access permissions to systems and security-protected access to filing cabinets and
                storage;
              </li>
              <li>
                Access only to authorised OCR Labs employees and contractors that require access to perform their daily
                duties; and
              </li>
              <li>
                Varying access levels depending on the level of the authority and the type of personal and sensitive
                information required to be accessed.
              </li>
            </ul>
            Controls relating to how personal and sensitive information is extracted from the secure environment and how
            it is used and distributed. We also regularly conduct security audits, vulnerability scans, and penetration
            tests to ensure compliance with security best practices and standards.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            11. Unsolicited personal and sensitive information
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            There may be circumstances where an individual provides us with the personal or sensitive information about
            another person. Where we receive unsolicited personal information which we do not require for the purposes
            we have outlined above, we will destroy or de-identity that information as soon as practicable (if it is
            lawful and reasonable to do so).
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            12. Accessing and correcting your information
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            You can access the personal information we hold about you by contacting us using the contact information
            below.
            <br />
            <br />
            Sometimes, we may not be able to provide you with access to all of your personal information and, where this
            is the case, we will provide you with a written notice explaining why. We may also need to verify your
            identity when you request your personal information.
            <br />
            <br />
            We note that we may not have stored your personal information where it was collected by us to perform
            verification services and such services have been completed.
            <br />
            <br />
            If you think that any personal information we hold about you is inaccurate, outdated, incorrect or
            incomplete, please contact us promptly and we will take reasonable steps to ensure that it is corrected.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            13. General Data Protection Regulation (GDPR) Privacy
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <small>Legal Basis for Processing Personal Data under GDPR</small>
            <br />
            <br />
            When we are providing our services to our clients we act as a processor to our clients (who are the
            controllers). It is up to our clients to establish the legal basis of processing, but it will be under one
            of the following conditions:
            <ul>
              <li>
                Consent: you have given your consent for processing biometric data for identification or authentication
                purposes. This is the only ground under which we will process your biometric information.
              </li>
              <li>
                Performance of a contract: processing of personal data is necessary for the performance of an agreement
                between you and our clients.
              </li>
              <li>
                Legitimate interests: processing of your personal data is necessary for the purposes of the legitimate
                interests pursued by our client which does not unduly prejudice you.
              </li>
            </ul>
            <small>Your Rights</small>
            <br />
            <br />
            We undertake to respect the confidentiality of your personal data and to guarantee you can exercise your
            rights.
            <br />
            <br />
            You have the right under this Privacy Policy, and by law depending on your jurisdiction, to:
            <ul>
              <li>
                Request access to your personal data. The right to access, update or delete the information we have on
                you.
                <ul>
                  <li>
                    <b>Request correction of the personal data that we hold about you.</b> You have the right to have
                    any incomplete or inaccurate information we hold about you corrected. We offer individuals we are
                    verifying the opportunity to amend incorrectly captured data as part of the identity verification
                    journey.
                  </li>
                  <li>
                    <b>Object to processing of your personal data.</b> This right exists where our client is relying on
                    a legitimate interest as the legal basis for its processing and there is something about your
                    particular situation, which makes you want to object to its processing of your personal data on this
                    ground.
                  </li>
                  <li>
                    <b>Request erasure of your personal data.</b> You have the right to ask our clients to delete or
                    remove personal data that we are holding when there is no good reason for us to continue processing
                    it.
                  </li>
                  <li>
                    <b>Request the transfer of your personal data.</b> We give the ability to our clients to export your
                    personal data in a structured, commonly used, machine-readable format. You can ask them for a copy
                    of the data we hold on their behalf about you.
                  </li>
                  <li>
                    <b>Withdraw your consent.</b> You have the right to withdraw your consent on using your biometric
                    data. If you withdraw your consent, we may not be able to provide you with access to certain
                    specific functionalities of the service.
                  </li>
                </ul>
              </li>
            </ul>
            <small>Exercising of Your Data Protection Rights</small>
            <br />
            <br />
            You may exercise your rights of access, rectification, cancellation and opposition by contacting us. Please
            note that we may ask you to verify your identity before responding to such requests. If you make a request,
            we will try our best to respond to you as soon as possible. Where our client is the data controller we will
            pass on the request to them.
            <br />
            <br />
            You have the right to complain to a Data Protection Authority about Our collection and use of your personal
            data. For more information, if you are in the European Economic Area (EEA) or the UK, please contact Your
            local data protection authority in the EEA or the UK.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            14. Using our website and cookies
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            When you visit our website (but not when you use our verification services) we may drop cookies.
            <br />
            <br />
            <b>What are cookies?</b> Cookies are small files that are stored on your computer or other device by your
            web browser.
            <br />
            <br />
            A cookie allows us to recognize whether you have used our services before and may store user preferences and
            other information.
            <br />
            <br />
            <b>How are cookies used?</b> For example, cookies can be used to collect information about your use of our
            services during your current session and over time, your computer or other device’s operating system and
            browser type, your Internet service provider, your domain name and IP address, and your general geographic
            location.
            <br />
            <br />
            <b>How do you avoid cookies?</b> If you are concerned about having cookies on your computer or device, you
            can set your browser to refuse all cookies or to indicate when a cookie is being set, allowing you to decide
            whether to accept it.
            <br />
            <br />
            You can also delete cookies from your computer.
            <br />
            <br />
            However, if you choose to block or delete cookies, certain features of our services may not operate
            correctly.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            15. Links
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Our website or apps may contain links to websites operated by third parties. Those links are provided for
            convenience and may not remain current or be maintained
            <br />
            <br />
            Unless expressly stated otherwise, we are not responsible for the privacy practices of, or any content on,
            those linked websites, and have no control over or rights in those linked websites.
            <br />
            <br />
            The privacy policies that apply to those other websites may differ substantially from our Privacy Policy, so
            we encourage individuals to read them before using those websites.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            16. Making a complaint
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            If you think we have breached the Privacy and/or applicable data protection laws, or you wish to make a
            complaint about the way we have handled your personal information, you can contact us at{" "}
            <b>connect@blue-bricks.com.</b>
            <br />
            <br />
            Please include your name and clearly describe your complaint.
            <br />
            <br />
            We will acknowledge your complaint and respond to you regarding your complaint within a reasonable period of
            time.
            <br />
            <br />
            If you think that we have failed to resolve the complaint satisfactorily, we will provide you with
            information about the further steps you can take, one of which is to lodge a complaint with your local
            privacy regulator.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            17. Contact us
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            For further information about our Privacy Policy or practices, or to access or correct your personal
            information, or make a complaint, please contact us promptly using the details set out below:
            <br />
            <br />
            Privacy Officer
            <br />
            e: connect@blue-bricks.com
            <br />
            a. Level Nine, 18 The Esplanade, Perth WA 6000 Australia
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            18. BlueBricks Group
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            This Privacy Policy covers the following entities, which form part of the our Group:
            <ul>
              <li>BlueBricks Pty Ltd (WA, Australia company)</li>
              <li>Molla Technologies Sdn Bhd (KL, Malaysia company)</li>
              <li>BlueBricks Technologies Pvt Ltd (MH, India company)</li>
            </ul>
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            19. Job Applicants
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            When you apply for a job or position with us, we may collect certain information from you (including your
            name, contact details, title, working history and relevant records checks) from any recruitment consultant,
            your previous employers and others who may be able to provide information to us to assist in our decision on
            whether or not to make you an offer of employment or engage you under a contract.
            <br />
            <br />
            We process that data under the lawful ground of: Legitimate interests – processing of your personal data is
            necessary for the purposes of the legitimate interests pursued by us which does not unduly prejudice you.
            <br />
            <br />
            Please contact our Privacy Officer <b>(connect@blue-bricks.com)</b> if you wish to enforce any of your
            rights under the applicable law that applies to us as data controller.
            <br />
            <br />
            If you are a client or prospect of ours then we may collect certain information from you in the ordinary
            course of a sale to you (including your name, contact details and title).
            <br />
            <br />
            If we have collected your personal information because you are a representative of one of our current or
            prospective partners or clients, we may send you direct marketing communications and information about
            services and products offered by members of Blue Bricks. This may take the form of emails, SMS, mail or
            other forms of communication, in accordance with the Privacy Act. You may opt-out of receiving marketing
            materials from us by contacting us using the details set out below or by using the opt-out facilities
            provided (e.g. an unsubscribe link). If we use your personal information for direct marketing, we will
            ensure we comply with our legal obligations.
            <br />
            <br />
            For individuals working for our clients or prospective clients your personal data is processed within the
            jurisdiction you operate in and may also be transferred to the USA by our partner Salesforce.
            <br />
            <br />
            We process that data under the lawful ground of: Legitimate interests – processing of your personal data is
            necessary for the purposes of the legitimate interests pursued by us which does not unduly prejudice you.
            <br />
            <br />
            Please contact our Privacy Officer (connect@blue-bricks.com) if you wish to enforce any of your rights under
            the applicable law that applies to us as data controller.
            <br />
            <br />
            Version 3
            <br />
            <br />
            Effective Date: 19 March 2024
            <br />
            <br />
            This Privacy Policy is review and updated at least annually.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicy;
