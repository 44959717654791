import React from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";
const TermsAndConditions = () => {
  return (
    <Grid container justifyContent="center" sx={{ minHeight: "100vh", backgroundColor: "whitesmoke" }}>
      <Grid item xs={12} sm={12} md={9} lg={8} sx={{color:'rgba(50, 71, 92, 0.87)'}}>
        <Box p={3} bgcolor="white" sx={{ py: 2, px: 3 }}>
          <Box textAlign="center" sx={{ backgroundColor: "#1976d2", color: "white", mt: 1, p: 2 }}>
            <Typography variant="h4">Terms And Conditions</Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            1. ACCEPTANCE OF TERMS AND CONDITIONS
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            These Terms and Conditions govern your ("Customer" or "you") use of Valydate4u's On-demand Electronic
            Signature Service (the "Hosted Services"), as accessed either directly through Valydate4u.com, or through a
            Valydate4u affiliate's web page offering a Service Plan (collectively, the "Site"). By depositing any
            document into the System (as defined below), you accept these Terms and Conditions (including your
            corresponding Service Plan, the Valydate4u.com Terms of Use, and all policies and guidelines referenced and
            hereby incorporated into these Terms and Conditions) and any modifications that may be made to the Terms and
            Conditions from time to time. If you do not agree to these Terms and Conditions, you should not use the
            Hosted Services or visit or browse the Site. These Terms and Conditions constitute a binding legal agreement
            between you and Valydate4u, Inc. ("Valydate4u," "we," "us," and "our"). Please read them carefully and print
            a copy for your future reference.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            2. MODIFICATION OF TERMS AND CONDITIONS
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            We reserve the right to modify these Terms and Conditions at any time and in any manner at our sole
            discretion by: (a) posting a revision on this Site; or (b) sending information regarding the amendment to
            the email address you provide to us. YOU ARE RESPONSIBLE FOR REGULARLY REVIEWING THIS SITE TO OBTAIN TIMELY
            NOTICE OF ANY AMENDMENTS. YOU SHALL BE DEEMED TO HAVE ACCEPTED SUCH AMENDMENTS BY CONTINUING TO USE THE
            HOSTED SERVICES FOR MORE THAN 20 DAYS AFTER SUCH AMENDMENTS HAVE BEEN POSTED OR INFORMATION REGARDING SUCH
            AMENDMENTS HAS BEEN SENT TO YOU. You agree that we shall not be liable to you or to any third party for any
            modification of the Terms and Conditions.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            3. DEFINITIONS
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            “Account” means a unique account established by the Customer in order to gain access for its Authorized
            Users to the Hosted Services.
            <br /><br />
            “Authorized User” means an individual employee or third party agent, as identified by a unique email address
            and user name, who is registered as a member of Customer's Account. No two persons may register, access or
            use the Hosted Services as the same Authorized User.
            <br /><br />
            “Customer Data” means Personal Data and all other data and information concerning Customer or Customer's
            personnel or business clients: (a) provided by or on behalf of Customer to Valydate4u; or (b) obtained by
            Valydate4u based on the use or Processing of data or information provided by or on behalf of Customer to
            Valydate4u.
            <br /><br />
            “Depositing Party” refers to an Authorized User that deposits a document into the System for Processing
            under the Hosted Services.
            <br /><br />
            “eContract” refers to a contract, notice, disclosure, or other record or document deposited into the System
            by a Depositing Party for Processing under the Hosted Services.
            <br /><br />
            “Envelope” means an electronic record containing one or more eContracts consisting of a single page or a
            group of pages of data uploaded to the System.
            <br /><br />
            “Risk Free” means our offer to allow you to use the Hosted Services for 30 calendar days after you activate
            your Account with a valid credit card. If you cancel your Account in accordance with these Terms and
            Conditions prior to the passage of 30 days, your credit card will not be charged. This Risk Free offer is
            available one time only to any person or entity.
            <br /><br />
            “Hosted Services” means Valydate4u's On-demand Electronic Signature Service, as updated from time to time,
            which provides on-line display, certified delivery, acknowledgement, electronic signature, and storage
            services for eContracts via the Internet as set forth in the Hosted Services Specifications (the
            “Specifications”)
            <br /><br />
            “Personal Data” means: (a) non-public personally identifiable information, including driver's license
            numbers, national identification numbers such as social security account numbers, credit card numbers,
            digital identity certificates; (b) personally identifiable financial information regarding a consumer (i)
            provided by a consumer to a financial institution, (ii) resulting from any transaction with the consumer or
            any service performed for the consumer by a financial institution, (iii) otherwise obtained by the financial
            institution, including any list, description, or other grouping of consumers (and publicly available
            information pertaining to them) that is derived using any non-public personal information; and (c)
            personally identifiable medical or health related information
            <br /><br />
            “Process” and similar terms mean to perform any operation or set of operations upon Customer Data, whether
            or not by automatic means, such as collection, recording, organization, storage, adaptation or alteration,
            retrieval, accessing, consultation, use, disclosure by transmission, dissemination or otherwise making
            available, alignment or combination, blocking, erasure or destruction.
            <br /><br />
            “Seat” means an active Authorized User listed in the membership of an Account at any one time. No two
            individuals may log onto or use the Hosted Services as the same Authorized User, but Customer may unregister
            or deactivate Authorized Users and replace them with other Authorized Users without penalty, so long as the
            number of active Authorized Users registered at any one time is equal to or less than the number of Seats
            purchased
            <br /><br />
            “Service Plan” means the right to access and use the Hosted Services for a specified period in exchange for
            a periodic fee, subject to the Service Plan restrictions and requirements that are used to describe the
            selected Service Plan on the Site. Restrictions and requirements may include any or all of the following:
            (a) number of Seats and/or Envelopes that a Customer may use in a month or year for a fee; (b) fee for sent
            Envelopes in excess of the number of Envelopes allocated to Customer under the Service Plan; (c) per-seat or
            per-user restrictions; (d) the license to use Valydate4u software products such as Valydate4u Connect
            Express in connection with the Hosted Services; and (e) per use fees.
            <br /><br />
            “System” refers to the software systems and programs, communication and network facilities, and hardware and
            equipment used by Valydate4u or its agents to provide the Hosted Services
            <br /><br />
            “Term” means the period of effectiveness of these Terms and Conditions, as specified in Section 11 below.
            <br /><br />
            “Transaction Data” means data associated with an eContract, including transaction history, eContract image
            hash value, information concerning method and time of eContract purge, and sender and recipient names, email
            addresses and signature IDs.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            4. HOSTED SERVICES
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Valydate4u will provide the Hosted Services in material conformance with the Specifications. Customer may
            obtain an Account and register Authorized Users, and subject to these Terms and Conditions, use the Hosted
            Services to Process, as applicable, eContracts. You must be 18 years of age or older to register for an
            Account and use the Hosted Services. Customer's right to use the Hosted Services is limited to its
            Authorized Users, and Customer agrees not to resell the use of the Hosted Services.
            <br /><br />
            Customer's use of the Hosted Services is subject to Customer's acknowledgement and agreement that: (a)
            nothing in these Terms and Conditions or under the Hosted Services will be construed to make Valydate4u a
            party to any eContract, and Valydate4u makes no representation or warranty regarding the transactions sought
            to be effected by any eContract; (b) Valydate4u maintains no control of or access to the contents of any
            eContract, and the content, quality, and format of any eContract is completely within the exclusive control
            of the Depositing Party and is the responsibility of Customer; (c) the Hosted Services may provide options
            designed to verify the identity of the intended recipient of an eContract deposited into the System
            ("Authentication Measures"), and Valydate4u: (i) will apply only those Authentication Measures (if any)
            selected by the Depositing Party; (ii) makes no representations or warranties regarding the appropriateness
            of such Authentication Measures; and (iii) assumes no liability or responsibility for a party's inability or
            failure to satisfy any particular Authentication Measure; (d) certain types of agreements and documents are
            excepted from electronic signature laws, such that they cannot be legally formed by electronic signatures;
            additionally, various agencies may have promulgated specific regulations that apply to electronic signatures
            and electronic records, and Valydate4u assumes no responsibility to determine whether any particular
            eContract is an exception to applicable electronic signature laws or whether it is subject to any particular
            agency promulgations and whether it can be legally formed by electronic signatures; (e) Customer is solely
            responsible for making available to third parties (including parties to its eContracts) all contracts,
            documents, and other records required by applicable law, including, without limitation, electronic signature
            laws and other laws that may require records relating to a transaction to be retained or made accessible for
            a certain period of time; and (f) certain laws or regulations may impose special requirements with respect
            to electronic transactions involving one or more “consumers.” These may include, among other things,
            requirements that the consumer consent to the method of contracting and/or that the consumer be provided
            with a copy, or access to a copy, of a paper or other non-electronic, written record of the transaction.
            Valydate4u assumes no responsibility to determine whether any particular transaction involves a consumer,
            nor does Valydate4u have any responsibility: (i) to furnish or obtain any such consents or to determine if
            any such consents have been withdrawn; (ii) to provide any information or disclosures in connection with any
            attempt to obtain any such consents; (iii) to provide legal review of, or to update or correct any
            information or disclosures previously given; (iv) to provide any such copies or access except as expressly
            provided in the Specifications for all transactions, consumer or otherwise; or (v) otherwise to comply with
            any such special requirements. Customer expressly undertakes to determine whether any consumer is involved
            in any eContract presented by Customer or its Authorized Users for Processing, and, if so, to comply with
            all requirements imposed by law on such eContracts or their formation
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            5. RESPONSIBILITY FOR CONTENT OF COMMUNICATIONS
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Customer agrees that it will not use or permit the use of the Hosted Services to send unsolicited mass
            mailings outside its organization, it being understood that the term “unsolicited mass mailings” includes
            all statutory and other common definitions, including all Commercial Electronic Marketing Messages as
            defined in the Australian Spam Act 2003. Customer agrees that it is solely responsible for the nature and
            content of all materials, works, data, statements, and other visual, graphical, written, or audible
            communications of any nature submitted by any Authorized User or otherwise Processed through its Account.
            Customer further agrees not to knowingly use the Hosted Services: (a) to communicate any message or material
            that is defamatory, harassing, libellous, threatening or obscene; (b) in a way that violates or infringes
            upon the intellectual property rights or the privacy or publicity rights of any person or entity or that may
            otherwise be unlawful or give rise to civil or criminal liability (other than contractual liability of the
            parties under eContracts Processed through the Hosted Services); or (c) in any manner that is likely to
            damage, disable, overburden, or impair the System or the Hosted Services or interfere in any way with the
            use or enjoyment of the Hosted Services by others; or (d) in any way that constitutes or encourages conduct
            that could constitute a criminal offense. Although Valydate4u does not actively monitor the content
            Processed through the Hosted Services, Valydate4u may at any time and without prior notice suspend any use
            of the Hosted Services and/or remove or disable any content as to which Valydate4u is made aware of a reason
            for concern as to such use or content. Valydate4u agrees to exert reasonable commercial efforts to provide
            Customer with notice of any such suspension or disablement before its implementation, or promptly
            thereafter.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            6. PRICING AND PER USE PURCHASES
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            The prices, features, and options of the Hosted Services available for an Account depend on the Service Plan
            selected by Customer. Customer may also purchase optional services on a periodic or per-use basis.
            Valydate4u may add or change the prices, features or options available with a Service Plan without notice.
            Customer's usage under a Service Plan is measured based on the actual number of Seats as described in the
            Service Plan on the Site. Once a per-Seat Service Plan is established, the right of the named Authorized
            User to access and use the Hosted Services is not transferable; any additional or different named Authorized
            Users must purchase per-Seat Service Plans to send Envelopes. Extra seats, users and/or per use fees will be
            charged as set forth in Customer's Service Plan if allowed by such Service Plan. If a Services Plan defines
            a monthly Envelope Allowance (i.e. # Envelopes per month allowed to be sent), all Envelopes sent in excess
            of the Envelope Allowance will incur a per-Envelope charge. Any unused Envelope Allowances will expire and
            not carry over from one billing period to another under a Service Plan. Customer's Account will be deemed to
            have consumed an Envelope at the time the Envelope is sent by Customer, regardless of whether Envelopes were
            received by recipients, or whether recipients have performed any actions upon any eContract in the Envelope.
            For Service Plans that specify the Envelope Allowance is “Unlimited,” Customer is allowed to send a
            reasonable number of Envelopes from the number of Seats purchased. If Valydate4u suspects that the number of
            Envelopes sent from a particular Seat or a group of Seats is abusive and/or unduly burdensome, Valydate4u
            will promptly notify Customer, discuss the use-case scenario with Customer and any continued monitoring,
            additional discussions and/or information required to make a final determination on the course of action
            based on such information. In the event Customer exceeds, in Valydate4u's sole discretion, reasonable use
            restrictions under a Service Plan, Valydate4u reserves the right to transfer Customer into a higher-tier
            Service Plan without notice. If you misrepresent your eligibility for any Service Plan, you agree to pay us
            the additional amount you would have been charged under the most favorable pricing structure for which you
            are eligible. Valydate4u may discontinue a Service Plan at any time, and with prior notice to you, may
            migrate your Account to a similar Service Plan that may carry a different fee. You agree to allow us to
            charge your credit card for the fees associated with a substitute Service Plan, even if those fees are
            higher than those you agreed to when you registered your Account.
            <br /><br />
            Optional services subject to per use charges, such as premium Authentication Measures, are measured at the
            time of use, and such charges are specific to the number of units of the service(s) used during the billing
            period.
            <br /><br />
            Optional services subject to periodic charges, such as additional secure storage, are charged on the same
            periodic basis as the Service Plan fees for the Hosted Services.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            7. CUSTOMER SUPPORT
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Valydate4u will provide customer support to customer as specified in the Service Plan selected by Customer,
            and that is further detailed on Valydate4u's website at{" "}
            <a href="https://bluebrickshelp.zendesk.com/hc/en-us/requests/new" target="_blank">
              https://bluebrickshelp.zendesk.com/hc/en-us/requests/new
            </a>{" "}
            and email on <b>connect@Valydate4u.com</b>.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            8. STORAGE
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Valydate4u will store eContracts per the terms of the Service Plan selected by Customer. For Service Plans
            that specify the Envelope storage amount is “Unlimited,” Valydate4u will store an amount of Envelopes that
            is not abusive and/or unduly burdensome, in Valydate4u's sole discretion. Customer may retrieve and store
            copies of eContracts for storage outside of the System at any time during the Term of the Service Plan when
            Customer is in good financial standing under these Terms and Conditions, and may delete or purge eContracts
            from the System at its own discretion. Valydate4u may, at its sole discretion, delete an uncompleted
            eContract from the System immediately and without notice upon earlier of: (i) expiration of the Envelope
            (where Customer has established an expiration for such Envelope, not to exceed 365 days); or (ii) expiration
            of the Term. Valydate4u assumes no liability or responsibility for a party's failure or inability to
            electronically sign any eContract within such a period of time. Transaction Data associated with eContracts
            will be retained by Valydate4u permanently.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            9. BUSINESS AGREEMENT BENEFITS
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            You may receive or be eligible for certain pricing structures, discounts, features, promotions, and other
            benefits (collectively, "Benefits") through a business or government customer's agreement with us (a
            "Business Agreement"). Any and all such Benefits are provided to you solely as a result of the corresponding
            Business Agreement and such Benefits may be modified or terminated without notice. If you use the Hosted
            Services where a business or government entity pays your charges or is otherwise liable for the charges, you
            authorize us to share your account information with that entity and/or its authorized agents. If you are
            enrolled in a Service Plan or receive certain Benefits tied to a Business Agreement with us, but you are
            liable for your own charges, then you authorize us to share enough account information with that entity and
            its authorized agents to verify your continuing eligibility for those Benefits and the Service Plan.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            10. FEES AND PAYMENT TERMS
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            The Service Plan rates, charges, and other conditions for use are set forth in the Site. Customer will pay
            Valydate4u the applicable charges for the Services Plan as set forth on the Site. If you add more Authorized
            Users than the number of Seats you purchased, we will add those Authorized Users to your Account and impose
            additional charges for such additional Seats on an ongoing basis. Charges for pre-paid Service Plans will be
            billed to Customer in advance. Charges for per use purchases and standard Service Plan charges will be
            billed in arrears.When you register for an Account, you will be required to provide Valydate4u with
            accurate, complete, and current credit card information for a valid credit card that you are authorized to
            use. You must promptly notify us of any change in your invoicing address or changes related to the credit
            card used for payment. By completing your registration for the Services Plan, you authorize Valydate4u or
            its agent to bill your credit card the applicable Service Plan charges, any and all applicable taxes, and
            any other charges you may incur in connection with your use of the Hosted Services, all of which will be
            charged to your credit card. Each time you use the Hosted Services, or allow or cause the Hosted Services to
            be used, you reaffirm that we are authorized to charge your credit card. You may terminate your Account and
            revoke your credit card authorization as set forth in the Term and Termination section of these Terms and
            Conditions.
            <br /><br />
            We will provide you with one invoice in a format we choose, which may change from time to time, for all
            Hosted Services associated with each Account and any charges of a third party on whose behalf we bill.
            Payment of all charges is due and will be charged to your credit card upon your receipt of an invoice.
            Billing cycle end dates may change from time to time. When a billing cycle covers less than or more than a
            full month, we may make reasonable adjustments and/or prorations. If your Account is a qualified business
            account and is approved by us in writing for corporate billing, charges will be accumulated, identified by
            Account identification number, and invoiced on a monthly basis. You agree that we may (at our option)
            accumulate charges incurred during your monthly billing cycle and submit them as one or more aggregate
            charges during or at the end of each cycle, and that we may delay obtaining authorization from your credit
            card issuer until submission of the accumulated charge(s). This means that accumulated charges may appear on
            the statement you receive from your credit card issuer.
            <br /><br />
            If Valydate4u does not receive payment from your credit card provider, you agree to pay all amounts due upon
            demand. Valydate4u reserves the right to correct any errors or mistakes that it makes even if it has already
            requested or received payment.
            <br /><br />
            Your credit card issuer's agreement governs your use of your credit card in connection with the Hosted
            Services, and you must refer to such agreement (not these Terms and Conditions) with respect to your rights
            and liabilities as a cardholder. You are solely responsible for any and all fees charged to your credit card
            by the issuer, bank, or financial institution including, but not limited to, membership, overdraft,
            insufficient funds, and over the credit limit fees. You agree to notify us about any billing problems or
            discrepancies within 20 days after they first appear on your invoice. If you do not bring them to our
            attention within 20 days, you agree that you waive your right to dispute such problems or discrepancies.
            <br /><br />
            We may modify the price, content, or nature of the Hosted Services and/or your Service Plan at any time. If
            we modify any of the foregoing terms, you may cancel your use of the Hosted Services. We may provide notice
            of any such changes by e-mail, notice to you upon log-in, or by publishing them on the Site. Your payment
            obligations survive any termination of your use of the Hosted Services before the end of the billing cycle.
            <br /><br />
            Any amount not paid when due will be subject to finance charges equal to 1.5% of the unpaid balance per
            month or the highest rate permitted by applicable usury law, whichever is less, determined and compounded
            daily from the date due until the date paid. Customer will reimburse any costs or expenses (including, but
            not limited to, reasonable attorneys' fees) incurred by Valydate4u to collect any amount that is not paid
            when due. Valydate4u may accept any check or payment in any amount without prejudice to Valydate4u's right
            to recover the balance of the amount due or to pursue any other right or remedy. Amounts due to Valydate4u
            under these Terms and Conditions may not be withheld or offset by Customer for any reason against amounts
            due or asserted to be due to Customer from Valydate4u. All amounts payable under these Terms and Conditions
            are denominated in United States dollars, and Customer will pay all such amounts in United States dollars.
            <br /><br />
            Other than federal and state net income taxes imposed on Valydate4u by the United States, Customer will bear
            all taxes, duties, and other governmental charges (collectively, “taxes”) resulting from these Terms and
            Conditions or transactions conducted in relation to these Terms and Conditions. Customer will pay any
            additional taxes as are necessary to ensure that the net amounts received and retained by Valydate4u after
            all such taxes are paid are equal to the amounts that Valydate4u would have been entitled to in accordance
            with these Terms and Conditions as if the taxes did not exist.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            11. DEPOSITS, SERVICE LIMITS, CREDIT REPORTS, AND RETURN OF BALANCES
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            You authorize us to ask consumer reporting agencies or trade references to furnish us with employment and
            credit information, and you consent to our rechecking and reporting personal and/or business payment and
            credit history if, in our sole discretion, we so choose. If you believe that we have reported inaccurate
            information about your account to a consumer reporting agency, you may send a written notice describing the
            specific inaccuracy to the address provided in the Notices section below. For you to use the Hosted
            Services, we may require a deposit or set a service limit. The deposit will be held as a partial guarantee
            of payment. It cannot be used by you to pay your invoice or delayed payment. Unless otherwise required by
            law, deposits may be mixed with other funds and will not earn interest. We reserve the right to increase
            your deposit if we deem appropriate. You may request that we re-evaluate your deposit on an annual basis,
            which may result in a partial or total refund of the deposit to you or credit to your account. If you
            default or these Terms and Conditions are terminated, we may, without notice to you, apply any deposit
            towards payment of any amounts you owe to us. After approximately 90 days following termination of these
            Terms and Conditions, any remaining deposit or other credit balance in excess of amounts owed will be
            returned without interest, unless otherwise required by law, to you at your last known address. You agree
            that any amounts under $15 will not be refunded to cover our costs of closing your account. If the deposit
            balance is undeliverable and returned to us, we will hold it for you for one year from the date of return
            and, during that period, we may charge a service fee against the deposit balance. You hereby grant us a
            security interest in any deposit we require to secure the performance of your obligations under these Terms
            and Conditions.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            12. TERM AND TERMINATION
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            The term of these Terms and Conditions for each Account begins on the date you register for an Account and
            ends when that Account is terminated (the “Term”). You may terminate your Account at any time upon 30 days
            advance written notice to Valydate4u following the Notice procedures set forth in these Terms and
            Conditions. For any termination (including when you switch your Account), you will be responsible for
            payment of all fees and charges through the end of the billing cycle in which termination occurs. IF THE
            PAYMENT TERMS YOU SELECTED FOR YOUR SERVICE PLANS ARE BASED ON MONTHLY PAYMENTS, YOU AFFIRMATIVELY CONSENT
            TO ALLOW VALYDATE4U TO CHARGE YOUR CREDIT CARD EACH MONTH UNTIL YOU NOTIFY US OF TERMINATION OF YOUR
            ACCOUNT. You will be in default of these Terms and Conditions if you: (a) fail to pay any amount owed to us
            or an affiliate of ours or any amount appearing on your invoice; (b) have amounts still owing to us or an
            affiliate of ours from a prior account; (c) breach any provision of these Terms and Conditions; (d) violate
            any policy applicable to the Hosted Services; (e) are subject to any proceeding under the Bankruptcy Code or
            similar laws; or (f) if, in our sole discretion, we believe that your continued use of the Hosted Services
            presents a threat to the security of other users of the Hosted Services. If you are in default, we may,
            without notice to you, suspend your Account and use of the Hosted Services, withhold refunds and terminate
            your Account, in addition to all other remedies available to us. We may require reactivation charges to
            reactivate your Account after termination or suspension. The following provisions will survive the
            termination of these Terms and Conditions and your Account: Sections 3, 9-16,19 and 21.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            13. CUSTOMER WARRANTIES
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            You hereby represent and warrant to Valydate4u that: (a) you have all requisite rights and authority to use
            the Hosted Services under these Terms and Conditions and to grant all applicable rights herein; (b) the
            performance of your obligations under these Terms and Conditions will not violate, conflict with, or result
            in a default under any other agreement, including confidentiality agreements between you and third parties;
            (c) you will use the Hosted Services for lawful purposes only and subject to these Terms and Conditions; (d)
            you are responsible for all use of the Hosted Services in your Account; (e) you are solely responsible for
            maintaining the confidentiality of your Account names and password(s); (f) you agree to immediately notify
            us of any unauthorized use of your Account of which you become aware; (g) you agree that Valydate4u will not
            be liable for any losses incurred as a result of a third party's use of your Account, regardless of whether
            such use is with or without your knowledge and consent; (h) you will not use the Hosted Services in any
            manner that could damage, disable, overburden or impair the System, or interfere with another's use of the
            Hosted Services; (i) any information submitted to Valydate4u by you is true, accurate, and correct; and (j)
            you will not attempt to gain unauthorized access to the System or the Hosted Services, other accounts,
            computer systems, or networks under the control or responsibility of Valydate4u through hacking, cracking,
            password mining, or any other unauthorized means.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            14. VALYDATE4U WARRANTIES
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Valydate4u represents and warrants that: (a) the Hosted Services as delivered to Customer and used in
            accordance with the Specifications will not infringe on any United States patent, copyright or trade secret;
            (b) the Hosted Services shall be performed in accordance with the Specifications in their then-current form
            at the time of the provision of such Hosted Services; (c) any Valydate4u Products that are software shall be
            free of harmful or illicit code, trapdoors, viruses, or other harmful features; (d) the proper use of the
            Hosted Services as unmodified by Customer in the formation of an eContract not involving any consumer will
            be sufficient under the Australian Electronic Transactions Act 1999 (ETA) (the “ESIGN Act”) to support the
            validity of such formation, to the extent provided in the ESIGN Act; (e) the proper use of the Hosted
            Services as unmodified by Customer in the formation of an eContract involving a consumer will be sufficient
            under the ESIGN Act to support the validity of such formation, to the extent provided in the ESIGN Act, so
            long as and provided that Customer complies with all special requirements for consumer eContracts, including
            and subject to those referenced in Section 4.(f) above; and (f) Valydate4u has implemented information
            security policies and safeguards to preserve the security, integrity, and confidentiality of Personal Data
            and to protect against unauthorized access and anticipated threats or hazards thereto, that meet the
            objectives of the Interagency Guidelines Establishing Standards for Safeguarding Customer Information as set
            forth in Australia's Privacy Act 1988, specifically its Privacy Amendment (Enhancing Privacy Protection) Act
            2012.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            15. DISCLAIMER OF WARRANTIES
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            EXCEPT FOR THE WARRANTIES EXPRESSLY PROVIDED IN SECTION 14 OF THESE TERMS AND CONDITIONS, THE HOSTED
            SERVICES AND THE SITE ARE PROVIDED “AS IS.” VALYDATE4U MAKES NO REPRESENTATION OR WARRANTY OF ANY KIND
            WHETHER EXPRESS, IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), OR STATUTORY, AS TO ANY MATTER WHATSOEVER.
            VALYDATE4U EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            QUALITY, ACCURACY, AND TITLE. VALYDATE4U DOES NOT WARRANT THAT THE HOSTED SERVICES OR SITE ARE OR WILL BE
            ERROR-FREE, WILL MEET CUSTOMER'S REQUIREMENTS, OR BE TIMELY OR SECURE. CUSTOMER WILL BE SOLELY RESPONSIBLE
            FOR ANY DAMAGE RESULTING FROM THE USE OF THE HOSTED SERVICES AND SITE. CUSTOMER WILL NOT HAVE THE RIGHT TO
            MAKE OR PASS ON ANY REPRESENTATION OR WARRANTY ON BEHALF OF VALYDATE4U TO ANY THIRD PARTY. USE OF THE HOSTED
            SERVICES AND SITE ARE AT YOUR SOLE RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOU RESULTING FROM
            THE USE OF THE HOSTED SERVICES OR SITE.
            <br /><br />
            Because some states and jurisdictions do not allow limitations on implied warranties, the above limitation
            may not apply to you. In that event, such warranties are limited to the minimum warranty period allowed by
            the applicable law.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            16. CUSTOMER INDEMNIFICATION OBLIGATIONS
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            You will defend, indemnify, and hold us, our affiliates, officers, directors, employees, suppliers,
            consultants, and agents harmless from any and all third party claims, liability, damages, and costs
            (including, but not limited to, attorneys' fees) arising from or related to: (a) your use of the Hosted
            Services; (b) your violation of these Terms and Conditions; (c) your infringement, or infringement by any
            other user of your Account, of any intellectual property or other right of any person or entity; or (d) the
            nature and content of all materials, works, data, statements, and other visual, graphical, written, or
            audible communications of any nature submitted by any Authorized User of your Account or otherwise Processed
            through your Account.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            17. LIMITATIONS OF LIABILITY
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THESE TERMS AND CONDITIONS, VALYDATE4U WILL NOT, UNDER
            ANY CIRCUMSTANCES, BE LIABLE TO CUSTOMER FOR ANY CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR EXEMPLARY DAMAGES
            ARISING OUT OF OR RELATED TO THE TRANSACTIONS CONTEMPLATED UNDER THESE TERMS AND CONDITIONS, INCLUDING BUT
            NOT LIMITED TO LOST PROFITS OR LOSS OF BUSINESS, EVEN IF APPRISED OF THE LIKELIHOOD OF SUCH DAMAGES
            OCCURRING. UNDER NO CIRCUMSTANCES WILL VALYDATE4U'S TOTAL LIABILITY OF ALL KINDS ARISING OUT OF OR RELATED
            TO THESE TERMS AND CONDITIONS OR CUSTOMER'S USE OF THE HOSTED SERVICES (INCLUDING BUT NOT LIMITED TO
            WARRANTY CLAIMS), REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON
            CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED THE TOTAL AMOUNT PAID BY CUSTOMER TO VALYDATE4U
            UNDER THESE TERMS AND CONDITIONS DURING THE 3 MONTHS PRECEDING THE DATE OF THE ACTION OR CLAIM. EACH
            PROVISION OF THESE TERMS AND CONDITIONS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF
            WARRANTIES, OR EXCLUSION OF DAMAGES REPRESENTS AN AGREED ALLOCATION OF THE RISKS OF THESE TERMS AND
            CONDITIONS BETWEEN THE PARTIES. THIS ALLOCATION IS REFLECTED IN THE PRICING OFFERED BY VALYDATE4U TO
            CUSTOMER AND IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE
            PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS AND CONDITIONS, AND EACH OF
            THESE PROVISIONS WILL APPLY EVEN IF THE WARRANTIES IN THESE TERMS AND CONDITIONS HAVE FAILED OF THEIR
            ESSENTIAL PURPOSE. Because some states and jurisdictions do not allow limitation of liability in certain
            instances, portions of the above limitation may not apply to you.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            18. CONFIDENTIALITY
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            “Confidential Information” means any trade secrets or other information of Valydate4u, whether of a
            technical, business, or other nature (including, without limitation, Valydate4u software and related
            information), that is disclosed to or made available to Customer. Confidential Information does not include
            any information that: (a) was known to Customer prior to receiving it from Valydate4u; (b) is independently
            developed by Customer without use of or reference to any Confidential Information; (c) is acquired by
            Customer from another source without restriction as to use or disclosure; or (d) is or becomes part of the
            public domain through no fault or action of Customer. During and after the Term of these Terms and
            Conditions, Customer will: (i) use the Confidential Information solely for the purpose for which it is
            provided; (ii) not disclose such Confidential Information to a third party; and (iii) protect such
            Confidential Information from unauthorized use and disclosure to the same extent (but using no less than a
            reasonable degree of care) that it protects its own Confidential Information of a similar nature. If
            Customer is required by law to disclose the Confidential Information or the terms of these Terms and
            Conditions, Customer must give prompt written notice of such requirement before such disclosure and assist
            the Valydate4u in obtaining an order protecting the Confidential Information from public disclosure.
            Customer acknowledges that, as between the parties, all Confidential Information it receives from
            Valydate4u, including all copies thereof in Customer's possession or control, in any media, is proprietary
            to and exclusively owned by Valydate4u. Nothing in these Terms and Conditions grants Customer any right,
            title, or interest in or to any of the Confidential Information. Customer's incorporation of the
            Confidential Information into any of its own materials shall not render Confidential Information
            non-confidential. Customer acknowledges that any actual or threatened violation of this confidentiality
            provision may cause irreparable, non-monetary injury to the disclosing party, the extent of which may be
            difficult to ascertain, and therefore agrees that Valydate4u shall be entitled to seek injunctive relief in
            addition to all remedies available to Valydate4u at law and/or in equity. Absent written consent of
            Valydate4u, the burden of proving that the Confidential Information is not, or is no longer, confidential or
            a trade secret shall be on Customer.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            19. PRIVACY
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            The privacy policy posted on Valydate4u's website sets forth the terms and conditions governing Valydate4u's
            collection and use of personal information from Authorized Users that is gathered through the Site.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            20. INTELLECTUAL PROPERTY
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            BlueBricks Pty Ltd , Australia is the owner of various intellectual property and technology rights
            associated with the Hosted Services, its document management, digital signature, and notary system,
            including patent, copyright, trade secret, and trademark and service mark rights. Except for the rights
            expressly granted in these Terms and Conditions, Valydate4u does not transfer to Customer of any Authorized
            User any of Valydate4u's technology or other intellectual property or technology rights. All right, title,
            and interest in and to Valydate4u's technology and intellectual property will remain solely with the
            Valydate4u. Customer agrees that it will not, directly or indirectly, reverse engineer, decompile,
            disassemble, or otherwise attempt to derive source code or other trade secrets from the Hosted Services or
            Valydate4u's technology. Valydate4u agrees that data and information provided by Customer under these Terms
            and Conditions shall remain, as between Customer and Valydate4u, owned by Customer.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            22. FEEDBACK
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            By submitting feedback to Valydate4u: (a) Customer automatically grants to Valydate4u a perpetual,
            irrevocable, transferable, royalty-free license to use Customer's feedback for any and all purposes without
            any compensation to Customer; and (b) Customer agrees that it will not publish, submit, or display feedback
            submitted by Customer or its Authorized Users to or on any other web site or in any other publicly
            accessible forum without Valydate4u's prior written consent.
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            23. GENERAL
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Customer acknowledges that the Hosted Services are subject to U.S. export control laws and regulations.
            Customer represents that it is not a citizen of an embargoed country or prohibited end user under applicable
            U.S. export and anti-terrorism laws, regulations, or lists. Customer will not export, re-export, transfer,
            or make available, whether directly or indirectly, any regulated item or information to anyone outside the
            United States in connection with these Terms and Conditions without first complying with all export control
            laws and regulations that may be imposed by the U.S. Government and any country or organization of nations
            within whose jurisdiction it operates or does business.
            <br /><br />
            Customer shall advise Valydate4u in the event the nature of its content or data transmission requires
            Valydate4u to obtain additional licenses, permits and/or approvals from any government in the jurisdiction
            where Customer intends to use the Hosted Services. Upon being advised of such a requirement, Valydate4u may
            at its sole discretion: (i) terminate Customer's Account; (ii) obtain such licenses, permits, and/or
            approvals as may be required; or (iii) modify these Terms and Conditions such that additional licenses,
            permits, and/or approvals are no longer required to be obtained by Valydate4u.
            <br /><br />
            The Hosted Services will be accessed and delivered via the internet. Customer is responsible for obtaining
            the necessary equipment and internet connection in order to access and use the Hosted Services. In order to
            fully utilize the Hosted Services, Customer will need to maintain certain minimum hardware and software
            requirements. These requirements are set forth in the Specifications.
            <br /><br />
            Valydate4u will be and act as an independent contractor (and not as the agent or representative of Customer)
            in the performance of these Terms and Conditions. These Terms and Conditions will not be interpreted or
            construed as: (a) creating or evidencing any association, joint venture, partnership, or franchise between
            the parties; (b) imposing any partnership or franchise obligation or liability on either party; (c)
            prohibiting or restricting either party's performance of any services for any third party; or (d)
            establishing or as a foundation for any rights or remedies for any third party, whether as a third party
            beneficiary or otherwise. Customer must not represent to anyone that Customer is an agent of Valydate4u or
            is otherwise authorized to bind or commit Valydate4u in any way without Valydate4u's prior authorization.
            <br /><br />
            Customer may not assign its rights, duties, or obligations under these Terms and Conditions without
            Valydate4u's prior written consent. If consent is given, these Terms and Conditions will bind Customer's
            successors and assigns. Any attempt by Customer to transfer its rights, duties, or obligations under these
            Terms and Conditions except as expressly provided in these Terms and Conditions is void. Valydate4u may
            freely assign its rights, duties, and obligations under these Terms and Conditions. Valydate4u may utilize a
            subcontractor or other third party to perform its duties under these Terms and Conditions so long as: (a)
            Valydate4u shall not be relieved of any responsibilities or obligations under these Terms and Conditions
            that are performed by the subcontractor or third party; and (b) Valydate4u shall remain Customer's sole
            point of contact and sole contracting party.
            <br /><br />
            We may provide, or third parties may provide, links to other Web sites or resources that are beyond our
            control. We make no representations as to the quality, suitability, functionality, or legality of any sites
            to which links may be provided, and you hereby waive any claim you might have against us with respect to
            such sites. VALYDATE4U IS NOT RESPONSIBLE FOR THE CONTENT ON THE INTERNET OR WEB PAGES THAT ARE CONTAINED
            OUTSIDE THE SITE. Your correspondence or business dealings with, or participation in promotions of,
            advertisers or partners found on or through the Site, including payment and delivery of related goods or
            services, and any other terms, conditions, warranties, or representations associated with such dealings, are
            solely between you and such advertiser or partner. You agree that we are not responsible or liable for any
            loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of
            such advertisers or partners on the Site.
            <br /><br />
            Any notice required or permitted to be given in accordance with these Terms and Conditions will be effective
            if it is in writing and sent using the certified delivery function of the Hosted Services, by email,
            certified or registered mail, or insured courier, return receipt requested, to the appropriate party at the
            address set forth in Customer's registration information for Customer or on the Site for Valydate4u. Either
            party may change its address for receipt of notice by notice to the other party in accordance with this
            Section. Notices are deemed given upon receipt if delivered using the Hosted Services or email, two business
            days following the date of mailing, or one business day following delivery to a courier.
            <br /><br />
            Neither party will be liable for, or be considered to be in breach of or default under these Terms and
            Conditions on account of, any delay or failure to perform as required by these Terms and Conditions as a
            result of any cause or condition beyond such party's reasonable control, so long as such party uses all
            commercially reasonable efforts to avoid or remove such causes of non-performance or delay.
            <br /><br />
            These Terms and Conditions are governed in all respects by the laws of the Australia as such laws are
            applied to agreements entered into and to be performed entirely within Washington between Washington
            residents. Any controversy or claim arising out of or relating to these Terms and Conditions, the Hosted
            Service, or the Site will be settled by binding arbitration in accordance with the commercial arbitration
            rules of the Australian Disputes Centre (ADC) for national customers and Australian Centre for International
            Commercial Arbitration (ACICA) for international customers. Any such controversy or claim shall be
            arbitrated on an individual basis, and shall not be consolidated in any arbitration with any claim or
            controversy of any other party. The arbitration will be conducted in Perth, Western Australia, and judgment
            on the arbitration award may be entered into any court having jurisdiction thereof. The award of the
            arbitrator shall be final and binding upon the parties without appeal or review except as permitted by
            Washington law. Notwithstanding the foregoing, either party may seek any interim or preliminary injunctive
            relief from any court of competent jurisdiction, as necessary to protect the party's rights or property
            pending the completion of arbitration. By using the Site or the Hosted Services, you consent and submit to
            the exclusive jurisdiction and venue of the state and federal courts located in Perth, Western Australia.
            Any legal action by Customer arising under these Terms and Conditions must be initiated within 6(six) months
            after the cause of action arises.
            <br /><br />
            The waiver by either party of any breach of any provision of these Terms and Conditions does not waive any
            other breach. The failure of any party to insist on strict performance of any covenant or obligation in
            accordance with these Terms and Conditions will not be a waiver of such party's right to demand strict
            compliance in the future, nor will the same be construed as a novation of these Terms and Conditions.
            <br /><br />
            If any part of these Terms and Conditions is found to be illegal, unenforceable, or invalid, the remaining
            portions of these Terms and Conditions will remain in full force and effect. If any material limitation or
            restriction on the grant of any license to Customer under these Terms and Conditions is found to be illegal,
            unenforceable, or invalid, the license will immediately terminate.
            <br /><br />
            Except as set forth in Section 2 of these Terms and Conditions, these Terms and Conditions may not be
            amended except in writing signed by both you and us. In the event that we make such a change that has a
            material adverse impact on your rights or use of the Service, you may terminate these Terms and Conditions
            by giving us notice within 20 days of the date we notify you, and you will not be charged any cancellation
            fee. These Terms and Conditions are the final and complete expression of the agreement between these parties
            regarding the Hosted Services. These Terms and Conditions supersede, and the terms of these Terms and
            Conditions govern, all previous oral and written communications regarding these matters.
            <br /><br />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TermsAndConditions;
