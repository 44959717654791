import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiService } from "../services/ApiService";

export const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [activeLicense, setActiveLicense] = useState(null);
  const [myOrgId, setMyOrgId] = useState("");
  const [myRights, setMyRights] = useState(null);
  const [myRole, setMyRole] = useState("");
  const [userOrgList, setUserOrgList] = useState([]);

  useEffect(() => {
    if (!currentUser) {
      getCurrentUser();
    }
  }, [currentUser]);

  useEffect(() => {
    if (myOrgId) {
      getActiveLicense(myOrgId);
    }
  }, [myOrgId]);

  const getLicenseFeature = (featureName) => {
    try {
      if (activeLicense) {
        let _featureName = featureName ? featureName : "all";

        const features = activeLicense?.include?.package?.features || [];
        if (features && features?.length > 0) {
          return features;
        }
        return null;
      }
      console.log({ featureName });
      return null;
    } catch (error) {
      console.log(error);
    }
  };

  const getLicenseMeta = () => {
    try {
      if (activeLicense) {
        return activeLicense?.meta || null;
      }
      return null;
    } catch (error) {
      console.log(error);
    }
  };

  let isLoadingCurrentUser = false;
  const getCurrentUser = async () => {
    if (isLoadingCurrentUser) return;
    isLoadingCurrentUser = true;

    if (!sessionStorage.getItem("opkey")) return;

    if (
      window.location.href.includes("/admin") ||
      window.location.href.includes("/account/activate") ||
      window.location.href.includes("/docs") ||
      window.location.href == 'https://licensing.axiomprotect.com/' || 
      window.location.href == "http://localhost:5678/"
    )
      return;

    apiService
      .get("operator/currentUser")
      .then((res) => res?.data || res)
      .then((response) => {
        isLoadingCurrentUser = false;
        toast.dismiss();
        if (response?.resultCode > 0) {
          setUserOrgList([...response?.data?.organizations]);
          setMyOrgId(response?.data?.organizations?.length > 0 ? response?.data?.organizations[0]?._id : null);
          setMyRights([...response?.data?.roleRights?.rights]);
          setMyRole(response?.data?.roleRights?.role || "USER");

          if (response?.data?.organizations) delete response.data.organizations;
          if (response?.data?.roleRights) delete response.data.roleRights;

          setCurrentUser({ ...response?.data });
        } else {
          toast.error(response?.message || "Fail");
        }
      })
      .catch((err) => {
        isLoadingCurrentUser = false;
        toast.dismiss();
        console.log(err);
        if (err?.response && err?.response?.data) {
          toast.error(err?.response?.data?.message);
        }
      });
  };

  let isTokenLoading = false;
  const getAccessRefreshToken = async (orgid) => {
    if (isTokenLoading) return;
    isTokenLoading = true;
    apiService
      .get(`operator/token/update/${orgid}`)
      .then((res) => res?.data || res)
      .then((response) => {
        isTokenLoading = false;
        toast.dismiss();
        if (response?.resultCode > 0) {
          setMyOrgId(orgid);
        } else {
          toast.error(response?.message || "Fail");
        }
      })
      .catch((err) => {
        isTokenLoading = false;
        toast.dismiss();
        console.log(err);
        if (err?.response && err?.response?.data) {
          toast.error(err?.response?.data?.message);
        }
      });
  };

  const getActiveLicense = async (orgid) => {
    apiService
      .get(`org/license/details/${orgid}`)
      .then((res) => res?.data || res)
      .then((response) => {
        toast.dismiss();
        if (response?.resultCode > 0) {
          setActiveLicense(response?.data);
        } else {
          toast.error(response?.message || "Fail to get license");
        }
      })
      .catch((err) => {
        toast.dismiss();
        console.log(err);
        if (err?.response && err?.response?.data) {
          toast.error(err?.response?.data?.message);
        }
      });
  };

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        myOrgId,
        setMyOrgId,
        userOrgList,
        myRights,
        setMyRights,
        myRole,
        setMyRole,
        setUserOrgList,
        getAccessRefreshToken,
        activeLicense,
        setActiveLicense,
        getLicenseFeature,
        getLicenseMeta,
        getActiveLicense,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
