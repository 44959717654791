import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { apiService } from "../../services/ApiService";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { UserContext } from "../../context/UserProvider";
import { LoginOutlined, LogoutOutlined, Visibility, VisibilityOff } from "@mui/icons-material";

export const LoginPage = ({ setLoginRegPage }) => {
  const navigate = useNavigate();
  const {
    currentUser,
    setCurrentUser,
    userOrgList,
    setUserOrgList,
    myRights,
    setMyRights,
    myOrgId,
    setMyOrgId,
    myRole,
    setMyRole,
  } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const savedUsername = Cookies.get("username");
    const savedPassword = Cookies.get("password");
    const savedRememberMe =
      Cookies.get("rememberMe") == undefined || Cookies.get("rememberMe") == null ? false : Cookies.get("rememberMe");
    if (savedRememberMe && Boolean(savedRememberMe) && savedRememberMe != "false") {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
    const islogin = sessionStorage.getItem("isLogin") ? true : false;
    const operatorId = sessionStorage.getItem("operatorId");
    if (islogin && operatorId) {
      // window.location.href = "/dashboard";
      navigate("/dashboard");
    } else {
      sessionStorage.removeItem("isLogin");
    }
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let body = {
      emailId: data.get("email"),
      password: data.get("password"),
    };

    if (!body.emailId || !body.password) {
      toast.warn("Please fill the required fields!");
      return;
    }
    toast.loading("Processing...");
    setLoading(true);

    apiService
      .post("authenticate/operator", body)
      .then((res) => res?.data || res)
      .then((response) => {
        toast.dismiss();
        if (response.resultCode > 0) {
          sessionStorage.setItem("isLogin", true);
          console.log("RES:", response.data, response.data?.organizations);
          setUserOrgList([...response.data?.organizations]);
          setMyOrgId(response.data?.organizations?.length > 0 ? response.data?.organizations[0]?._id : null);
          setMyRights([...response.data?.roleRights?.rights]);
          setMyRole(response?.data?.roleRights?.role || "USER");

          sessionStorage.setItem("operatorId", response.data?.userId);
          sessionStorage.setItem("opkey", response.data?.opkey);

          if (response?.data?.organizations) delete response.data.organizations;
          if (response?.data?.roleRights) delete response.data.roleRights;

          setCurrentUser({ ...response.data });

          toast.success(response.message || "Done");

          if (rememberMe) {
            Cookies.set("username", body.emailId);
            Cookies.set("password", body.password);
            Cookies.set("rememberMe", "true");
          } else {
            Cookies.set("username", "");
            Cookies.set("password", "");
            Cookies.set("rememberMe", "false");
          }

          // window.location.href = "/dashboard";
          navigate("/dashboard");
        } else {
          toast.error(response.message || "Fail");
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.dismiss();
        setLoading(false);
        console.log(err);
        if (err?.response && err?.response?.data) {
          toast.error(err?.response?.data?.message);
        }
      });

    sessionStorage.setItem("jwtToken", "success");
  };

  return (
    <>
      <Box sx={{ justifyContent: "center" }}>
        <img src="/Valydate4u.png" style={{ margin: "auto 0.5rem", width: "16rem", height: "100%" }} />
        {/* <span>
          {" "}
          <Typography component="h1" variant="h6">
            Licensing
          </Typography>
          <Typography variant="caption">Welcome to Licensing.</Typography>
        </span> */}
      </Box>
      <Typography component="h1" variant="h5">
        Sign In
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }} autoFocus>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          InputLabelProps={{ shrink: true }}
        />

        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            fullWidth
            required
            name="password"
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        {/* <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          autoComplete="current-password"
          InputLabelProps={{ shrink: true }}
        /> */}

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <FormControlLabel
            value={rememberMe}
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => {
                  setRememberMe(e.target.checked);
                }}
                color="primary"
              />
            }
            label="Remember me"
          />
          <Link
            href="#"
            variant="body2"
            onClick={() => {
              window.location.href = "/forgotpassword";
            }}
          >
            Forgot password?
          </Link>
        </Box>

        <Button disabled={loading} type="submit" fullWidth variant="contained" sx={{ mt: 1 }}>
          Sign In
        </Button>
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mt: 3 }}>
          <Typography variant="p">If you don't have an account please click below to sign up</Typography>
          <Button
            type="button"
            sx={{ width: "auto", mt: 2 }}
            variant="outlined"
            startIcon={<LoginOutlined />}
            onClick={() => {
              setLoginRegPage(true);
            }}
          >
            Go To Sign Up
          </Button>
        </Box>
      </Box>
    </>
  );
};
