import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MainListItems, secondaryListItems } from "../SideBarMenu";
import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import PermIdentitySharpIcon from "@mui/icons-material/PermIdentitySharp";
import CustomToast from "../CustomToast";
import { apiService } from "../../services/ApiService";
import { toast } from "react-toastify";
import {
  AccountBox,
  Close,
  DocumentScanner,
  EditNote,
  Launch,
  Security,
  Tungsten,
  VideoSettings,
  ViewList,
} from "@mui/icons-material";

import copy from "copy-to-clipboard";
import { UserContext } from "../../context/UserProvider";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      style={{
        position: "fixed",
        bottom: 0,
        marginLeft: "25%",
        marginRight: "auto",
        transform: "translate(50%, 0)",
      }}
    >
      {"Copyright © "}
      <a href={window?.location.href} style={{ textDecoration: "none" }}>
        {window?.location?.hostname || "Blue Bricks Licensing"}
      </a>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: "90vw",
    maxHeight: "90vh",
    width: "90vw",
    height: "90vh",
  },
  dialogContent: {
    padding: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
    zIndex: 1,
  },
  videoContainer: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();

  const { myOrgId, currentUser,setCurrentUser,myRights, userOrgList, getAccessRefreshToken } = React.useContext(UserContext);

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [videoUrl, setVideoUrl] = React.useState(null);
  const [showDialog, setShowDialog] = React.useState(false);

  React.useEffect(() => {
    if (currentUser?.showStarter) {
      setShowDialog(true);
      setVideoUrl(process.env?.REACT_APP_STARTER_VIDEO);
    }
  }, [currentUser]);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = (event) => {
    sessionStorage.clear();
    window.location.replace("/");
  };

  const handleCloseDialog = () => {
    closeStarter();
    setShowDialog(false);
    setVideoUrl(null);

    setCurrentUser((prevState)=>{
      return {...currentUser,showStarter:false}
    })
  };

  setInterval(() => {
    try {
      apiService
        .post("authenticate/token/refreshToken", {})
        .then((res) => res?.data || res)
        .then((response) => {
          toast.dismiss();
          // console.log(response);
          if (response.resultCode > 0) {
            sessionStorage.setItem("isLogin", true);
          } else {
            toast.error(response.message || "Session expired");
            setTimeout(() => {
              sessionStorage.removeItem("isLogin");
              sessionStorage.removeItem("jwtToken");
              window.location.href = "/";
            }, 1500);
          }
        })
        .catch((err) => {
          toast.dismiss();
          toast.error("Session expired.");
          sessionStorage.removeItem("isLogin");
          sessionStorage.removeItem("jwtToken");
          window.location.href = "/";
          console.log(err);
        });
    } catch (error) {
      console.log(error);
      toast.error("Session expired..");
      sessionStorage.removeItem("isLogin");
      sessionStorage.removeItem("jwtToken");
      window.location.href = "/";
    }
  }, 540000);

  const copyLink = (link) => {
    let updatedEndPoint = `${window.location.origin}/${link}?opkey=${sessionStorage.getItem("opkey")}`;
    copy(updatedEndPoint);
    toast.success("Link copied");
    setTimeout(() => {
      window.open(updatedEndPoint, "_blank");
    }, 1000);
  };

  const closeStarter = () => {
    try {
      apiService
        .patch("operator/update/showStarter", {})
        .then((res) => res?.data || res)
        .then((response) => {
          toast.dismiss();
        })
        .catch((err) => {
          console.log(err);
          toast.dismiss();
          toast.error(err?.message || "Something went wrong");
        });
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Something went wrong.");
    }
  };
  return (
    <main className="">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "1rem",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-start" }}
            >
              {open ? (
                <></>
              ) : (
                <img src="/Valydate4u.png" style={{ margin: "auto 0.5rem", width: "10rem", height: "100%" }} />
              )}
            </Typography>

            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}

            <Select
              value={myOrgId}
              onChange={(e) => {
                getAccessRefreshToken(e?.target?.value);
              }}
              variant="outlined"
              size="small"
              sx={{ minWidth: "150px", color: "white" }}
            >
              {userOrgList?.map((e, i) => (
                <MenuItem key={e?._id} value={e?._id}>
                  {e?.companyName}
                </MenuItem>
              ))}
            </Select>

            <IconButton color="inherit" sx={{ marginLeft: 3 }} onClick={handleMenu}>
              <Avatar>
                <PermIdentitySharpIcon />
              </Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              sx={{ mt: "45px" }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to="/operator" onClick={handleClose}>
                <AccountBox sx={{ mr: 1 }} /> View Profile
              </MenuItem>
              <Divider />
              <MenuItem component={Link} to="/my/license" onClick={handleClose}>
                <Security sx={{ mr: 1 }} /> My License
              </MenuItem>
              <Divider />
              <MenuItem>
                <Typography variant="caption">Share below links to client</Typography>
              </MenuItem>

              <MenuItem>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    copyLink("newlicense");
                    handleClose();
                  }}
                  title="Click to copy"
                  endIcon={<Launch />}
                >
                  {" "}
                  New License Link
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    copyLink("reNewlicense");
                    handleClose();
                  }}
                  title="Click to copy"
                  endIcon={<Launch />}
                >
                  {" "}
                  Upgrade License Link
                </Button>
              </MenuItem>
              <Divider />
              <MenuItem onClick={logoutUser}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <Typography variant="h6" sx={{ display: "flex", justifyContent: "center", flexDirection:'column', alignItems:'center', width: "100%" }}>
              {" "}
              <img src="/Valydate4u.png" style={{ width: "200px", marginTop:'3px' }} />
              
              
              <Typography variant="p" component='small' sx={{fontSize:'0.7rem', mt:'5px'}}>Logged in as <span style={{color:"#1976d2"}}>{currentUser?.role}</span></Typography>
            </Typography>
            <IconButton onClick={toggleDrawer} sx={{p:0}}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          {/* <Divider /> */}
          <List component="nav">
            <MainListItems myRights={myRights}/>
            <Divider sx={{ my: 1 }} />
            <ListItemButton
              onClick={() => {
                navigate("/audits");
              }}
            >
              <ListItemIcon>
                <ViewList fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Audit Logs"
              />
            </ListItemButton>
            <Divider sx={{ my: 1 }} />
            <ListItemButton
              onClick={() => {
                navigate("/how-to-videos");
              }}
            >
              <ListItemIcon>
                <VideoSettings fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="How To?"
              />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                window.open(`${window.location.origin}/docs`, "_blank");
              }}
            >
              <ListItemIcon>
                <DocumentScanner fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Documentation"
              />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                window.open(`${window.location.origin}/sdk/playground/init`, "_blank");
              }}
            >
              <ListItemIcon>
                {/* <Tungsten fontSize="small" /> */}
                <Launch fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary={"SDK Playground"}
              />
            </ListItemButton>

            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ my: 4 }}>
            <CustomToast />
            {children}

            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>

      <Dialog
        open={showDialog}
        // fullWidth
        // maxWidth="lg"
        onClose={() => handleCloseDialog()}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent className={classes.dialogContent}>
          <IconButton
            size="small"
            color="secondary"
            aria-label="close"
            onClick={() => handleCloseDialog()}
            className={classes.closeButton}
          >
            <Close />
          </IconButton>
          {currentUser?.showStarter && videoUrl && <Box className={classes.videoContainer}>
            <iframe
              src={new URL(`${videoUrl}?showinfo=1&autoplay=1`)}
              // frameBorder="0"
              title="dialogVideo"
              allow="autoplay; encrypted-media"
              allowFullScreen
              className={classes.iframe}
            ></iframe>
          </Box>}
          
        </DialogContent>
      </Dialog>
    </main>
  );
};

export default MainLayout;
