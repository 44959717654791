import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import React, { lazy, Suspense, useEffect, useState } from "react";
import { BlankLayout } from "./components";
import { Box, ThemeProvider, Typography, createTheme } from "@mui/material";
import Page404 from "./pages/Page404";

import "react-toastify/dist/ReactToastify.css";
import { LoginRegistration } from "./pages/LoginRegistration/LoginRegistration";

import DocsLayout from "./components/Layouts/DocsLayout";
import PlaygroundLayout from "./components/Layouts/PlaygroundLayout";

import PasswordSet from "./pages/LoginRegistration/PasswordSet";
import ForgotPassword from "./pages/LoginRegistration/ForgotPassword";
import PaymentBillUpload from "./pages/Licenses/PaymentBillUpload";
import BillUploadSuccess from "./pages/Licenses/BillUploadSuccess";
import BuyNewLicense from "./pages/Licenses/BuyNewLicense";
import ReNewLicense from "./pages/Licenses/ReNewLicense";
import AuthRoutes from "./AuthRoutes";
import PaymentSuccess from "./pages/PaymentSuccess";
import LoginPage from "./pages/ADMIN/LoginPage";
import AdminAuthRoutes from "./AdminAuthRoutes";

import UserProvider from "./context/UserProvider";
import AdminProvider from "./context/AdminProvider";
import PlaygroundProvider from "./context/PlaygroundProvider";

import Loading from "./components/Loading";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import EmailSendSuccess from "./pages/EmailSendSuccess";
import PasswordSetSuccess from "./pages/PasswordSetSuccess";

const defaultTheme = createTheme();

export default function App() {
  const Description = lazy(() => import("./pages/PlayGround/Description"));
  const Initialize = lazy(() => import("./pages/PlayGround/Initialize"));
  const UpdateLicense = lazy(() => import("./pages/PlayGround/UpdateLicense"));
  const GetLicenseDetails = lazy(() => import("./pages/PlayGround/GetLicenseDetails"));
  const GetFeatures = lazy(() => import("./pages/PlayGround/GetFeatures"));
  const AesRsaPage = lazy(() => import("./pages/PlayGround/AesRsaPage"));

  const Overview = lazy(() => import("./pages/DOCS/Overview"));
  const Teams = lazy(() => import("./pages/DOCS/Teams"));
  const Products = lazy(() => import("./pages/DOCS/Products"));
  const Packages = lazy(() => import("./pages/DOCS/Packages"));
  const NewRequests = lazy(() => import("./pages/DOCS/NewRequests"));
  const ReNewRequests = lazy(() => import("./pages/DOCS/ReNewRequests"));
  const Organizations = lazy(() => import("./pages/DOCS/Organizations"));
  const SDK = lazy(() => import("./pages/DOCS/SDK"));
  const JavaSDK = lazy(() => import("./pages/DOCS/JavaSDK"));
  const PythonSDK = lazy(() => import("./pages/DOCS/PythonSDK"));
  const CSharpSDK = lazy(() => import("./pages/DOCS/CSharpSDK"));
  const JsTsSDK = lazy(() => import("./pages/DOCS/JsTsSDK"));
  const AndroidSDK = lazy(() => import("./pages/DOCS/AndroidSDK"));
  const IosSDK = lazy(() => import("./pages/DOCS/IosSDK"));
  const ReportApi = lazy(() => import("./pages/DOCS/ReportApi"));
  const StripeIntegrate = lazy(() => import("./pages/DOCS/StripeIntegrate"));
  const PackageListingAPI = lazy(() => import("./pages/DOCS/PackageListingAPI"));
  const PaymentLinkAPI = lazy(() => import("./pages/DOCS/PaymentLinkAPI"));

  return (
    <ThemeProvider theme={defaultTheme}>
      <PlaygroundProvider>
        <AdminProvider>
          <UserProvider>
            <BrowserRouter>
              <AuthRoutes />
              <AdminAuthRoutes />

              <Routes>
                {/* OPEN Routes without login */}

                <Route
                  path="/"
                  element={
                    <BlankLayout>
                      <LoginRegistration />
                    </BlankLayout>
                  }
                />

                <Route
                  path="/forgotpassword"
                  element={
                    <BlankLayout>
                      <ForgotPassword />
                    </BlankLayout>
                  }
                />

                <Route
                  path="/account/activate/:userId"
                  element={
                    <BlankLayout>
                      <PasswordSet />
                    </BlankLayout>
                  }
                />

                <Route
                  path="/newlicense"
                  element={
                    <BlankLayout>
                      <BuyNewLicense />
                    </BlankLayout>
                  }
                />

                <Route
                  path="/reNewlicense"
                  element={
                    <BlankLayout>
                      <ReNewLicense />
                    </BlankLayout>
                  }
                />

                <Route
                  path="/client-bill-upload/:reqId/:type"
                  element={
                    <BlankLayout>
                      <PaymentBillUpload />
                    </BlankLayout>
                  }
                />

                <Route
                  path="/billUpload/success"
                  element={
                    <BlankLayout>
                      <BillUploadSuccess />
                    </BlankLayout>
                  }
                />

                <Route
                  path="/subscription/payment/success"
                  element={
                    <BlankLayout>
                      <PaymentSuccess />
                    </BlankLayout>
                  }
                />

                <Route
                  path="/terms-and-conditions"
                  element={
                    <BlankLayout>
                      <TermsAndConditions />
                    </BlankLayout>
                  }
                />
                <Route
                  path="/privacy-policy"
                  element={
                    <BlankLayout>
                      <PrivacyPolicy />
                    </BlankLayout>
                  }
                />

                <Route
                  path="/auth-success-notify"
                  element={
                    <BlankLayout>
                      <EmailSendSuccess />
                    </BlankLayout>
                  }
                />
                <Route
                  path="/pass-success-notify"
                  element={
                    <BlankLayout>
                      <PasswordSetSuccess />
                    </BlankLayout>
                  }
                />

                <Route
                  path="/sdk/playground"
                  element={
                    <PlaygroundLayout>
                      <Suspense fallback={<Loading />}>
                        <Description />
                      </Suspense>
                    </PlaygroundLayout>
                  }
                />

                <Route
                  path="/sdk/playground/init"
                  element={
                    <PlaygroundLayout>
                      <Suspense fallback={<Loading />}>
                        <Initialize />
                      </Suspense>
                    </PlaygroundLayout>
                  }
                />

                <Route
                  path="/sdk/playground/update"
                  element={
                    <PlaygroundLayout>
                      <Suspense fallback={<Loading />}>
                        <UpdateLicense />
                      </Suspense>
                    </PlaygroundLayout>
                  }
                />

                <Route
                  path="/sdk/playground/details"
                  element={
                    <PlaygroundLayout>
                      <Suspense fallback={<Loading />}>
                        <GetLicenseDetails />
                      </Suspense>
                    </PlaygroundLayout>
                  }
                />

                <Route
                  path="/sdk/playground/features"
                  element={
                    <PlaygroundLayout>
                      <Suspense fallback={<Loading />}>
                        <GetFeatures />
                      </Suspense>
                    </PlaygroundLayout>
                  }
                />

                <Route
                  path="/sdk/playground/encr-decr"
                  element={
                    <PlaygroundLayout>
                      <Suspense fallback={<Loading />}>
                        <AesRsaPage />
                      </Suspense>
                    </PlaygroundLayout>
                  }
                />

                <Route
                  path="/sdk/playground/encr-decr"
                  element={
                    <PlaygroundLayout>
                      <Suspense fallback={<Loading />}>
                        <AesRsaPage />
                      </Suspense>
                    </PlaygroundLayout>
                  }
                />

                <Route
                  path="/admin"
                  element={
                    <BlankLayout>
                      <LoginPage />
                    </BlankLayout>
                  }
                />

                {/** Documentation Routes  STARTS */}
                <Route
                  path="/docs"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <Overview />
                      </Suspense>
                    </DocsLayout>
                  }
                />
                <Route
                  path="/docs/teams"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <Teams />
                      </Suspense>
                    </DocsLayout>
                  }
                />
                <Route
                  path="/docs/products"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <Products />
                      </Suspense>
                    </DocsLayout>
                  }
                />
                <Route
                  path="/docs/packages"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <Packages />
                      </Suspense>
                    </DocsLayout>
                  }
                />
                <Route
                  path="/docs/newrequests"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <NewRequests />
                      </Suspense>
                    </DocsLayout>
                  }
                />
                <Route
                  path="/docs/renewrequests"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <ReNewRequests />
                      </Suspense>
                    </DocsLayout>
                  }
                />
                <Route
                  path="/docs/orgs"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <Organizations />
                      </Suspense>
                    </DocsLayout>
                  }
                />

                <Route
                  path="/docs/sdk"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <SDK />
                      </Suspense>
                    </DocsLayout>
                  }
                />

                <Route
                  path="/docs/sdk/js-ts"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <JsTsSDK />
                      </Suspense>
                    </DocsLayout>
                  }
                />

                <Route
                  path="/docs/sdk/python"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <PythonSDK />
                      </Suspense>
                    </DocsLayout>
                  }
                />

                <Route
                  path="/docs/sdk/java"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <JavaSDK />
                      </Suspense>
                    </DocsLayout>
                  }
                />

                <Route
                  path="/docs/sdk/c-sharp"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <CSharpSDK />
                      </Suspense>
                    </DocsLayout>
                  }
                />

                <Route
                  path="/docs/sdk/android"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <AndroidSDK />
                      </Suspense>
                    </DocsLayout>
                  }
                />

                <Route
                  path="/docs/sdk/ios"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <IosSDK />
                      </Suspense>
                    </DocsLayout>
                  }
                />

                <Route
                  path="/docs/report-api"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <ReportApi />
                      </Suspense>
                    </DocsLayout>
                  }
                />

                <Route
                  path="/docs/stripe-integration"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <StripeIntegrate />
                      </Suspense>
                    </DocsLayout>
                  }
                />

                <Route
                  path="/docs/package-listing-api"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <PackageListingAPI />
                      </Suspense>
                    </DocsLayout>
                  }
                />

                <Route
                  path="/docs/payment-link-api"
                  element={
                    <DocsLayout>
                      <Suspense fallback={<Loading />}>
                        <PaymentLinkAPI />
                      </Suspense>
                    </DocsLayout>
                  }
                />

                {/** Documentation Routes  ENDS */}

                {/* <Route path="/*" element={<Page404 />} /> */}
              </Routes>
            </BrowserRouter>
          </UserProvider>
        </AdminProvider>
      </PlaygroundProvider>
    </ThemeProvider>
  );
  // }
}
