import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Paper,
  Container,
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Box,
  Switch,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import phone from "phone";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { apiService } from "../../services/ApiService";
import ProductSelection from "./ProductSelection";
import PackageSelect from "./PackageSelect";

const useStyles = makeStyles((theme) => ({
  selectedRow: {
    backgroundColor: "#b4c9f4",
  },
}));

const BuyNewLicense = () => {
  const classes = useStyles();

  const [seconds, setSeconds] = useState(10);
  const [startTimer, setStartTimer] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isValidCompanyName, setIsValidCompanyName] = useState(false);

  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileCountry, setMobileCountry] = useState(null);

  const [noOfLicenses, setNoOfLicenses] = useState(1);

  const [allProductList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [allPackageList, setAllPackageList] = useState([]);

  const [isMultiLicense, setIsMultiLicense] = useState(false);
  const [noOfLicenseError, setNoOfLicenseError] = useState(false);
  const [serverList, setServerList] = useState([]);
  const [currentCountry, setCurrentCountry] = useState("");
  const [currentGeoLocation, setCurrentGeoLocation] = useState(null);

  useEffect(() => {
    getGeoInfo();

    const currentUrl = window.location.href;

    const url = new URL(currentUrl);
    const opkey = url.searchParams.get("opkey");

    sessionStorage.setItem("opkey", opkey);

    getAllProductsList();
    // getAllPackagesList();

    return () => {};
  }, []);

  useEffect(() => {
    let timer = null;
    if (startTimer) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => {
          // Redirect when the countdown reaches 0
          if (prevSeconds === 1) {
            clearInterval(timer);
            setStartTimer(false);
            window.location.reload();
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => {
      return timer ? clearInterval(timer) : null;
    };
  }, [startTimer]);

  const getGeoInfo = async () => {
    try {
      await fetch("https://geolocation-db.com/json/")
        .then(async function (response) {
          const res = await response.json();
          setCurrentGeoLocation(res);
          setCurrentCountry(res?.country_name || "");
        })
        .catch((err) => {
          console.log("Geo Location GET API Error", err);
        });
    } catch (error) {
      console.log("GET >> Geo Location :: ", error);
    }
  };

  let isProductListLoding = false;
  const getAllProductsList = () => {
    if (isProductListLoding) return;
    isProductListLoding = true;
    apiService
      // .get("license/api/getProductList")
      .get("/exposed/list/products")
      .then((res) => res?.data || res)
      .then((response) => {
        toast.dismiss();
        if (response.resultCode > 0) {
          setProductList(response?.data || []);
        } else {
          toast.error(response.message || "Fail");
        }
      })
      .catch((err) => {
        toast.dismiss();
        console.log(err);
        if (err?.response && err?.response?.data) {
          toast.error(err?.response?.data?.message);
        }
      });
  };

  const handleNext = () => {
    let isVerified = false;
    if (activeStep === 0) {
      /** Verify User Details */

      isVerified = verifyUserDetail();
    } else if (activeStep == 1) {
      if (serverList?.length === 0) {
        toast.warn(`Please select the product`);
        return;
      }
      isVerified = true;
    } else if (activeStep == 2) {
      const invalidData = serverList?.find((e) => e.pack == null);

      if (invalidData) {
        toast.warn(`Please select the package for ${invalidData?.title}`);
        return;
      }
      isVerified = true;
    } else isVerified = true;
    if (isVerified) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (noOfLicenses > 1) {
      if (allPackageList?.length > 0) {
        let _serverPackages = [];
        for (let i = 0; i < noOfLicenses; i++) {
          _serverPackages.push({
            title: `Select Package for license ${i + 1}`,
            pack: null,
            packageList: allPackageList || [],
          });
        }
        setServerList(_serverPackages);
      }
    }
  }, [noOfLicenses]);

  const validateMobileNumber = (number, code) => {
    // var _code = code ? code.toString().toUpperCase() : "in";

    // var checkObj = phone(number);

    // var isValid = checkObj.isValid ? checkObj.isValid : false;
    // var isCodeMatch = checkObj.countryIso2 ? checkObj.countryIso2 == _code : false;
    // // console.log({ checkObj });
    // const isValidPhoneNumber = isValid;

    // return isValidPhoneNumber;

    return isValidPhoneNumber(number);
  };

  const verifyUserDetail = () => {
    if (!firstName || !email || !mobile || !companyName) {
      toast.warn("Please fill the required fields!");
      return false;
    }

    if (!mobile) {
      toast.error(`Please enter contact/mobile number!`);
      return false;
    }

    const _mobile = mobile?.includes("+") ? mobile : "+" + mobile;
    if (!validateMobileNumber(_mobile, mobileCountry)) {
      toast.error(`Invalid contact/mobile number!`);
      return false;
    }
    if (isValidCompanyName) {
      toast.error(`Invalid company name!`);
      return false;
    }
    if (noOfLicenses <= 0 || noOfLicenses > 5) {
      toast.error(`Please enter 1 to 5 number of license required.`);
      return false;
    }

    return true;
  };

  const handleConfirm = () => {
    if (!mobile) {
      toast.error(`Please enter contact/mobile number!`);
      return;
    }

    const _mobile = "+" + mobile;
    if (!validateMobileNumber(_mobile, mobileCountry)) {
      toast.error(`Invalid contact/mobile number!`);
      return;
    }

    let _packagesListReq = serverList.map((e) => e?.pack).filter((pack) => pack !== null);

    let body = {
      firstName: firstName,
      lastName: lastName,
      emailId: email,
      mobile: mobile.includes("+") ? mobile : "+" + mobile,
      companyName: companyName,
      products: selectedProduct ? [selectedProduct] : null,
      packages: _packagesListReq.length ? _packagesListReq : null,
      timeStamp: new Date(),
      numberOfServers: noOfLicenses,
    };

    if (!body.firstName || !body.emailId) {
      toast.warn("Please fill the required fields!");
      return;
    }

    apiService
      // .post("license/api/request", body)
      .post("exposed/license/request/new", body)
      .then((res) => res?.data || res)
      .then((response) => {
        toast.dismiss();
        if (response.resultCode > 0) {
          toast.success(response.message);
          setStartTimer(true);
        } else {
          toast.error(response.message || "Fail");
        }
      })
      .catch((err) => {
        toast.dismiss();
        console.log(err);
        if (err?.response && err?.response?.data) {
          toast.error(err?.response?.data?.message);
        }
      });
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Divider sx={{ my: 1 }} />
            <Typography variant="h6" align="center">
              User Details
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="First Name"
                  fullWidth
                  value={firstName}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => {
                    let input = e.target.value.replace(/[^A-Za-z]/g, "");
                    setFirstName(input);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="Last Name"
                  fullWidth
                  value={lastName}
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) => {
                    let input = e.target.value.replace(/[^A-Za-z]/g, "");
                    setLastName(input);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="Email"
                  fullWidth
                  value={email}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => {
                    let input = e.target.value
                      .replace(/[^A-Za-z0-9\s\.@]/g, "")
                      .replace(/\s+/g, " ")
                      .replace(/^\./g, "")
                      .replace(/@+/g, "@")
                      .replace(/\.\.+/g, ".");
                    setEmail(input);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", alignItems: "flex-end", marginTop: "0rem" }}>
                  <PhoneInput
                    specialLabel="Contact Number"
                    country={"in"}
                    inputProps={{
                      name: "phoneNumber",
                      required: true,
                      autoFocus: true,
                      style: {
                        height: 56,
                        width: "100%",
                        borderColor: "#ccc",
                      },
                    }}
                    value={mobile}
                    onChange={(phoneNumber, country) => {
                      setMobile(phoneNumber);
                      setMobileCountry(country.countryCode);
                    }}
                  />
                </Box>{" "}
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  label="Company Name"
                  fullWidth
                  value={companyName}
                  error={isValidCompanyName}
                  inputProps={{ maxLength: 70 }}
                  onChange={(e) => {
                    let input = e.target.value.replace(/[^A-Za-z0-9\s\.]/g, "").replace(/\s+/g, " ");

                    setCompanyName(input);

                    if (/^[0-9]*$/.test(input)) {
                      setIsValidCompanyName(true);
                    } else {
                      setIsValidCompanyName(false);
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormGroup sx={{ mt: 1, ml: 1 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        value={isMultiLicense}
                        onChange={(e) => {
                          setIsMultiLicense(e.target.checked);
                          if (!e?.target?.checked) {
                            setNoOfLicenses(1);
                          }
                        }}
                      />
                    }
                    label="Multiple Licences"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  type="text"
                  label="Number of Devices/Servers"
                  helperText={noOfLicenseError ? `You can select 1 to 5 number of license required at a time.` : ""}
                  disabled={!isMultiLicense}
                  value={noOfLicenses}
                  error={noOfLicenseError}
                  inputProps={{ maxLength: 2 }}
                  onChange={(e) => {
                    let input = e.target.value.replace(/[^0-9]/g, "");

                    let n = Number(input);

                    let no_lic = n > 5 ? 5 : n < 0 ? 0 : n;

                    setNoOfLicenses(no_lic);

                    if (n < 1 || no_lic >= 6) {
                      setNoOfLicenseError(true);
                    } else {
                      setNoOfLicenseError(false);
                    }
                  }}
                  min="1"
                  max="5"
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        );
      case 1:
        return (
          <ProductSelection
            isNew={true}
            noOfLicenses={noOfLicenses}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            allProductList={allProductList}
            allPackageList={allPackageList}
            setAllPackageList={setAllPackageList}
            setServerList={setServerList}
            currentCountry={currentCountry}
            setCurrentCountry={setCurrentCountry}
          />
        );
      case 2:
        return (
          <PackageSelect
            selectedProduct={selectedProduct}
            noOfLicenses={noOfLicenses}
            serverList={serverList}
            setServerList={setServerList}
            currentCountry={currentCountry}
            setCurrentCountry={setCurrentCountry}
          />
        );
      case 3:
        return (
          <>
            <Divider sx={{ my: 1 }} />
            <Typography variant="h6" align="center">
              Confirm Details
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography>
                  Full Name: <strong>{`${firstName} ${lastName}`}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  Email: <strong>{email}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  Mobile: <strong>{mobile}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  Company: <strong>{companyName}</strong>
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography>
                  Product (Code):<strong> {`${selectedProduct?.productName} (${selectedProduct?.productCode})`}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>
                  Country: <strong>{currentCountry}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Total Required Licenses:<strong>{noOfLicenses > 0 ? noOfLicenses : 1}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr. No.</TableCell>
                        <TableCell>Package</TableCell>
                        <TableCell>Validity</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Selected For</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {serverList &&
                        serverList?.map((e, ind) => {
                          return (
                            <TableRow key={`tr_in1_${ind}`}>
                              <TableCell>{`${ind+1}`}</TableCell>
                              <TableCell>
                                {e?.pack
                                  ? `${e?.pack?.name}`
                                  : "<No Package Selected>"}
                              </TableCell>
                              <TableCell>
                                {e?.pack
                                  ? `${e?.pack?.durationDays} Days`
                                  : "<No Package Selected>"}
                              </TableCell>
                              <TableCell>
                                {e?.pack
                                  ? `$${e?.pack?.price}`
                                  : "<No Package Selected>"}
                              </TableCell>
                              <TableCell>{e?.title}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src="/Valydate4u.png" style={{ margin: "auto 0.5rem", width: "16rem", height: "100%" }} />
        </Box>
        <Typography variant="h4" align="center">
          New License Request
        </Typography>
        <Divider sx={{ my: 2 }} />
        {startTimer ? (
          <>
            <Typography variant="h2" color="lightgrey" align="center" sx={{ margin: "3rem" }}>
              {seconds}
            </Typography>
            <Typography variant="h5" align="center">
              Thanks for request, you will receive email soon for license key.
            </Typography>
          </>
        ) : (
          <>
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step key="Personal Info">
                <StepLabel />
              </Step>
              <Step key="Select Product">
                <StepLabel />
              </Step>
              <Step key="Select Package">
                <StepLabel />
              </Step>
              <Step key="Confirm">
                <StepLabel />
              </Step>
            </Stepper>
            {getStepContent(activeStep)}

            <div style={{ marginTop: 20 }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} style={{ marginRight: 10 }}>
                  Back
                </Button>
              )}
              {activeStep !== 3 ? (
                <Button variant="contained" color="primary" onClick={handleNext}>
                  Next
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={handleConfirm}>
                  Confirm and Submit
                </Button>
              )}
            </div>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default BuyNewLicense;
